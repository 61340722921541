module.exports = ['T0A',
'T0B',
'T0C',
'T0E',
'T0G',
'T0H',
'T0J',
'T0J',
'T0K',
'T0L',
'T0M',
'T0P',
'T0V',
'T1A',
'T1B',
'T1C',
'T1G',
'T1H',
'T1J',
'T1K',
'T1M',
'T1P',
'T1R',
'T1V',
'T4J',
'T4T',
'T7A',
'T7E',
'T7N',
'T7P',
'T7S',
'T7V',
'T8R',
'T8S',
'T8T',
'T8V',
'T8W',
'T8X',
'T9C',
'T9H',
'T9J',
'T9K',
'T9M',
'T9N',
'T9S',
'T9V',
'T9W',
'T9X',
]