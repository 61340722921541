<template>
  <div>
    <div v-if="!loading">
      <div class="title is-6">Save Hub/Warehouse at Region {{region}}? </div>
      <div class="is-size-6 has-text-weight-semibold has-text-danger">{{msg}}</div>
      <div class="is-flex is-flex-direction-row is-align-items-baseline">
        <!-- <div class="is-size-6 has-text-weight-semibold">Zone Name: </div> -->
        <!-- <div class="is-size-7">{{zone}}</div> -->
      </div>
      <div class="notification is-white">
        <div class="field">
          <label class="label is-small">Hub Type</label>
          <div class="control">
            <div class="select is-small">
              <select v-model="hubType">
                <option value="main">Main</option>
                <option value="sub region">Sub Region</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Region</label>
          <div class="control">
            <div class="select is-small">
              <select v-model="regionHub">
                <option value="calgary">Calgary</option>
                <option value="edmonton">Edmonton</option>
                <option value="vancouver">Vancouver</option>
                <option value="victoria">Victoria</option>
                <option value="kelowna">Kelowna</option>
                <option value="gta">GTA</option>
                <option value="ottawa">Ottawa</option>
                <option value="london">London</option>
                <option value="quebec">Quebec</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Name</label>
          <div class="control">
            <input class="input is-small" v-model="nameHub" type="text" placeholder="Name">
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Facility</label>
          <div class="control">
            <div class="select is-small">
              <select v-model="facility">
                <option value="business">Business</option>
                <option value="warehouse">Warehouse</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Size</label>
          <div class="control">
            <input class="input is-small" v-model="size" type="number" placeholder="Size">
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Hub Index</label>
          <div class="control">
            <input class="input is-small" v-model="hubIndex" type="text" maxlength="4" placeholder="Shortcut name for markers">
          </div>
        </div>
      </div>
      <div class="columns notification is-white">
        <div class="column"></div>
        <div class="column">
          <button class="button is-warning is-small" @click="closeModal()"> Close </button>
        </div>
        <div class="column">
          <button
            class="button is-small is-primary"
            @click="() => {
              saveToFirestore();
            }"
          > Save </button>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <div
      style="display: flex; align-items: center;justify-content: center;flex-direction: column;padding: 0;margin: 0;"
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { hubsCollection } from '../store/fireStore'
import EventHandler from '../EventHandler'

export default {
  components: {
    PulseLoader
  },
  data () {
    return {
      msg: '',
      zone: '',
      loading: false,
      hubType: 0,
      regionHub: '',
      nameHub: '',
      facility: 0,
      size: 0,
      hubIndex: ''
    }
  },
  props: {
    coordinates: {
      type: Array
    },
    region: {
      type: String
    },
    color: {
      type: String
    }
  },
  methods: {
    closeModal () {
      EventHandler.$emit('closeModal')
      // this.remove()
      this.close()
    },
    async saveToFirestore () {
      // this.loading = true
      // const randomColor = () => {
      //   const letters = '0123456789ABCDEF'
      //   let color = '#'
      //   for (let i = 0; i < 6; i++) {
      //     color += letters[Math.floor(Math.random() * 16)]
      //   }
      //   return color
      // }
      const hubType = (this.hubType)
      const regionHub = this.regionHub
      const nameHub = this.nameHub
      const facility = (this.facility)
      const size = (this.size)
      const hubIndex = this.hubIndex
      const color = this.color
      const coordinates = this.coordinates
      // console.log('---------------')
      // console.log({
      //   hubType,
      //   regionHub,
      //   nameHub,
      //   facility,
      //   size,
      //   color,
      //   coordinates
      // })
      const query = await hubsCollection.where('hubIndex', '==', hubIndex).get()
      if (regionHub && nameHub && hubType && facility && size && hubIndex) {
        if (query.empty) {
          try {
            const docRef = await hubsCollection.add({
              coordinates: JSON.stringify(coordinates),
              color,
              regionHub: regionHub.trim(),
              nameHub,
              hubType,
              facility,
              size,
              hubIndex
            })
            console.log('DOCREF ID: ', docRef)
            this.loading = false
            EventHandler.$emit('closeModal')
            this.close(hubIndex)
            // this.onSave(this.zone, docRef.id)
            this.msg = ''
          } catch (err) {
            console.log('ERR: ', err)
          }
        } else {
          this.loading = false
          this.msg = 'Hub name existing'
        }
      } else {
        this.msg = 'Fill up the missing inputs'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet/dist/leaflet.css";
  .custom .leaflet-popup-tip,
  .custom .leaflet-popup-content-wrapper {
      background: #e93434;
      color: #ffffff;
  }
</style>
