<template>
  <div>
    <div class="" v-if="!loading">
      <div class="tabs is-toggle is-centered is-rounded is-small is-primary">
        <ul>
          <li :class="{ 'is-active': activeTab === 1 }">
            <a @click="activeTab = 1">
              <span>Zone</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 2 }">
            <a @click="activeTab = 2">
              <span>Trucks</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 3 }">
            <a @click="activeTab = 3">
              <span>Service</span>
            </a>
          </li>
          <li :class="{ 'is-active': activeTab === 4 }">
            <a @click="activeTab = 4">
              <span>Hubs</span>
            </a>
          </li>
        </ul>
      </div>
      <md-list class="md-double-line" v-if="activeTab === 1">
        <div class="title is-5">Edit Zone:</div>
        <div class="is-size-6 has-text-weight-semibold has-text-danger">
          {{ msg }}
        </div>
        <div
          class="is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-baseline"
        >
          <div class="notification">
            <div
              class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
            >
              Zone:
            </div>
            <div class="is-size-6 container is-fluid px-0">
              <!-- {{shape.zone}} -->
              <multiselect
                v-model="zone"
                :options="zoneList"
                label="value"
                :close-on-select="true"
                track-by="value"
              ></multiselect>
            </div>
          </div>
          <div class="notification">
            <div
              class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold"
            >
              Region:
            </div>
            <div class="is-size-6">{{ shape.region }}</div>
          </div>
        </div>
        <!-- <div class="notification is-white">
          <input class="input is-small" type="text" v-model="zone" placeholder="Insert new zone name" />
        </div> -->
        <div class="columns notification is-white">
          <div class="column has-text-centered">
            <button
              class="button is-warning is-small"
              @click="closeModal()"
              v-if="!editNameMode"
            >
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button class="button is-info is-small" @click="addMoreShape()">
              Add By Drawing
            </button>
          </div>
          <div class="column has-text-centered">
            <button
              class="button is-error is-small"
              @click="deletePartsShape()"
            >
              Delete By Drawing
            </button>
          </div>
          <div class="column has-text-centered">
            <button
              :class="isFSAButtonLoading ? 'button is-primary is-small is-loading' : 'button is-primary is-small'"
              @click="editSelectableFSAMode()"
            >
              Edit In FSA Mode
            </button>
          </div>
          <div class="column has-text-centered" v-if="nameChanged">
            <button class="button is-small is-primary" @click="editFirestore">
              Save
            </button>
          </div>
        </div>
      </md-list>
      <md-list class="md-double-line" v-if="activeTab === 2">
        <div class="title is-5">Assign Truck:</div>
        <div class="notification is-white">
          <!-- <select
              style="width:120px;"
              @change="onChangeTruck"
          >
            <option
              v-for="(option, index) in selectedTrucks"
              :key="index"
              :value="`${JSON.stringify([`${option.name}`,index])}`"
            >{{ option.name }}</option>
          </select> -->
          <template>
            <div>
              <multiselect
                v-model="trucksToBeAssigned"
                :options="selectedTrucks"
                label="name"
                :multiple="true"
                :close-on-select="false"
                :taggable="true"
                track-by="name"
              ></multiselect>
            </div>
          </template>
        </div>
        <div class="columns notification is-white">
          <div class="column has-text-centered">
            <button class="button is-warning is-small" @click="closeModal()">
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button class="button is-small is-primary" @click="assignTruck">
              Assign Truck
            </button>
          </div>
        </div>
      </md-list>
      <md-list class="md-double-line md-dense" v-if="activeTab === 3">
        <div class="title is-5">Days of Service:</div>
        <div class="notification is-white">
          <template>
            <div>
              <multiselect
                v-model="daysAssigned"
                :options="days"
                label="day"
                :multiple="true"
                :close-on-select="false"
                :taggable="true"
                track-by="day"
              ></multiselect>
            </div>
          </template>
        </div>
        <div class="columns notification is-white">
          <div class="column has-text-centered">
            <button class="button is-warning is-small" @click="closeModal()">
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button class="button is-small is-primary" @click="assignDays">
              Assign Days
            </button>
          </div>
        </div>
      </md-list>
      <md-list class="md-double-line md-dense" v-if="activeTab === 4">
        <div class="title is-5">Assign Hub:</div>
        <div class="notification is-white">
          <template>
            <div>
              <multiselect
                v-model="hubsAssigned"
                :options="hubs"
                label="nameHub"
                :multiple="true"
                :close-on-select="false"
                :taggable="true"
                track-by="hubIndex"
              ></multiselect>
            </div>
          </template>
        </div>
        <div class="columns notification is-white">
          <div class="column has-text-centered">
            <button class="button is-warning is-small" @click="closeModal()">
              Close
            </button>
          </div>
          <div class="column has-text-centered">
            <button class="button is-small is-primary" @click="assignHub">
              Assign Hub
            </button>
          </div>
        </div>
      </md-list>
    </div>
    <div
      style="display: flex; align-items: center;justify-content: center;flex-direction: column;padding: 0;margin: 0;"
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Multiselect from 'vue-multiselect'
import store from '../store'
import { fleetSettings, zonesInfoCollection } from '../store/fireStore'
import { changeZoneId } from '../store/mongoStore'
import EventHandler from '../EventHandler'

// const randomColor = () => {
//   const letters = '0123456789ABCDEF'
//   let color = '#'
//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.floor(Math.random() * 16)]
//   }
//   return color
// }

export default {
  components: {
    PulseLoader,
    Multiselect,
  },
  data() {
    return {
      zone: {
        value: '',
        zoneNameId: '',
        shapesId: ''
      },
      msg: '',
      loading: false,
      activeTab: 1,
      currentIndex: 0,
      days: [
        { day: 'Sunday' },
        { day: 'Monday' },
        { day: 'Tuesday' },
        { day: 'Wednesday' },
        { day: 'Thursday' },
        { day: 'Friday' },
        { day: 'Saturday' },
      ],
      daysAssigned: [],
      hubsAssigned: [],
      editNameMode: false,
      isFSAButtonLoading: false
    }
  },
  props: {
    coordinates: {
      type: Array,
    },
    shape: {
      type: Object,
    },
    vehicles: {
      type: Array,
    },
    store: {
      type: Object,
    },
  },
  methods: {
    closeModal() {
      EventHandler.$emit('closeModal')
      this.close()
      this.editNameMode = false
    },
    addMoreShape() {
      this.addMoreShapeFunc(true)
    },
    deletePartsShape() {
      this.addMoreShapeFunc(false)
    },
    editSelectableFSAMode() {
      this.isFSAButtonLoading = true
      setTimeout(async () => {
        await this.selectedFSAModeEdit()
        this.isFSAButtonLoading = false
      }, 500)
    },
    async editFirestore() {
      this.loading = true
      const zone = this.zone.value ? this.zone.value.trim() : ''
      const zoneId = this.zone.zoneNameId ? this.zone.zoneNameId.trim() : ''
      const shape = this.shape
      const shapeId = shape.id.trim()
      if (zone && zoneId) {
        try {
          await changeZoneId(zoneId, shapeId)
          // this.editZone(zone, this.shape.zone)
          this.shape.zone = zone
          this.loading = false
          this.close()
          this.editNameMode = false
          await this.renderMapFunc()
        } catch (err) {
          this.loading = false
          this.msg = 'Error in editing zone'
        }
      } else {
        this.loading = false
        this.msg = 'Please select name'
      }
    },
    async onChangeTruck(e) {
      const [, index] = JSON.parse(e.target.value)
      this.currentIndex = index
    },
    async assignTruck() {
      new Promise((resolve) => {
        this.trucksToBeAssigned.map((truck) => {
          const payload = {
            value: this.shape.zone,
            key: 'zone',
            index: truck.truckIndex,
            region: this.store.state.region,
          }
          this.store.dispatch('setTruckFleetSetting', payload)
        })
        return resolve({})
      }).then(async () => {
        await fleetSettings
          .doc(`${this.store.state.region}`)
          .set({ fleet: this.store.state.selectedVehicles })
        this.trucksToBeAssigned = []
      })
    },
    async assignDays() {
      this.loading = true
      const daysToSave = this.daysAssigned.map((d) => d.day)
      try {
        await zonesInfoCollection
          .doc(this.shape.zoneId)
          .update({
            daysAssigned: daysToSave,
          })
        this.loading = false
        this.close()
        await this.renderMapFunc()
      } catch (err) {
        this.loading = false
        this.msg = 'Zone name existing'
      }
    },
    async assignHub() {
      const hubIndexes = this.hubsAssigned.map((h) => h.hubIndex)
      try {
        await zonesInfoCollection
          .doc(this.shape.zoneId)
          .update({
            hubsAssigned: hubIndexes,
          })
        this.loading = false
        this.close()
        await this.renderMapFunc()
      } catch (err) {
        this.loading = false
        this.msg = 'Assign Error, Please try again'
      }
    },
  },
  computed: {
    zoneList() {
      const currentRegion = store.state.region.trim()
      const zoneNames = store.state.zoneInformations.filter(zone => zone.region === currentRegion).map((zone) => ({
        value: zone.value.trim(),
        zoneNameId: zone.id.trim(),
      }))
      return zoneNames
    },
    currentTruck() {
      return this.store.state.selectedVehicles[this.currentIndex]
    },
    selectedTrucks() {
      return this.store.state.selectedVehicles.map((t, index) => ({
        ...t,
        truckIndex: index,
      }))
    },
    hubs() {
      return this.store.state.hubs
    },
    nameChanged() {
      return this.shape.zone.trim() !== this.zone.value.trim()
    },
    trucksToBeAssigned: {
      get: function() {
        console.log(
          'this.store.state.trucksToBeAssigned: ',
          this.store.state.trucksToBeAssigned
        )
        return this.store.state.trucksToBeAssigned
      },
      set: function(newValue) {
        this.store.dispatch('setTrucksToBeAssigned', [...newValue])
      },
    },
    hubsToBeAssigned: {
      get: function() {
        return this.store.state.hubsToBeAssigned
      },
      set: function(newValue) {
        console.log('newValue: ', newValue)
        this.store.dispatch('setHubsToBeAssigned', [...newValue])
      },
    },
  },
  mounted() {
    this.zone.value = this.shape.zone
    // console.log('this.zone.value: ', this.zone.value)
    this.zone.zoneNameId = this.shape.zoneNameId
    if (this.shape.daysAssigned) {
      this.daysAssigned = this.shape.daysAssigned.map((m) => ({ day: m }))
    }
    if (this.shape.hubsAssigned) {
      const hubsAssigned = this.shape.hubsAssigned.map((zoneHub) => {
        const hub = this.hubs.find((h) => h.hubIndex === zoneHub)
        return hub
      })
      this.hubsAssigned = hubsAssigned
      // console.log('hubAssigned: ', this.shape.hubsAssigned)
      // console.log('hubs', this.hubs)
      //
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import '~leaflet/dist/leaflet.css';
.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
  background: #e93434;
  color: #ffffff;
}
</style>
