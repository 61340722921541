import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const getInitialState = () => ({
  region: 'calgary',
  selectedVehicles: [],
  regionZones: [],
  hubs: [],
  zoneInformations: [],
  trucksToBeAssigned: [],
  hubsToBeAssigned: [],
  selectedFSAMode: false,
  fleetSettings: {} as any,
  environment: ''
})

export default new Vuex.Store({
  plugins: [createPersistedState({ paths: ['selectedDate', 'region'] })],
  state: getInitialState(),
  mutations: {
    setRegion (state, val) {
      state.region = val
    },
    setSelectedVehicles (state, val) {
      state.selectedVehicles = val
    },
    setInitialFleetSettings (state, val) {
      state.fleetSettings = val
    },
    setTruckFleetSetting (state, val) {
      const index = val.index
      const key = val.key
      const value = val.value || ''
      const region = val.region

      if (val.innerKey) {
        const innerKey = val.innerKey
        state.fleetSettings[region][index][key][innerKey] = value
      } else {
        state.fleetSettings[region][index][key] = value
      }
    },
    setRegionZones (state, val) {
      state.regionZones = val
    },
    setTrucksToBeAssigned (state, val) {
      state.trucksToBeAssigned = val
    },
    setHubs (state, val) {
      state.hubs = val
    },
    setZoneInformations (state, val) {
      state.zoneInformations = val
    },
    setHubsToBeAssigned (state, val) {
      state.hubsToBeAssigned = val
    },
    setSelectedFSAMode (state, val) {
      state.selectedFSAMode = val
    },
    setEnvironment (state, val) {
      state.environment = val
    }
  },
  actions: {
    setRegion ({ commit }, payload) {
      commit('setRegion', payload)
    },
    setSelectedVehicles ({ commit }, payload) {
      commit('setSelectedVehicles', payload)
    },
    setInitialFleetSettings ({ commit }, payload) {
      commit('setInitialFleetSettings', payload)
    },
    setTruckFleetSetting ({ commit }, payload) {
      commit('setTruckFleetSetting', payload)
    },
    setRegionZones ({ commit }, payload) {
      commit('setRegionZones', payload)
    },
    setTrucksToBeAssigned ({ commit }, payload) {
      commit('setTrucksToBeAssigned', payload)
    },
    setHubs ({ commit }, payload) {
      commit('setHubs', payload)
    },
    setZoneInformations ({ commit }, payload) {
      commit('setZoneInformations', payload)
    },
    setHubsToBeAssigned ({ commit }, payload) {
      commit('setHubsToBeAssigned', payload)
    },
    setSelectedFSAMode ({ commit }, payload) {
      commit('selectedFSAMode', payload)
    },
    setEnvironment ({ commit }, payload) {
      commit('setEnvironment', payload)
    }
  },
  modules: {
  }
})
