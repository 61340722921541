<template>
  <div>
    <div v-if="!loading">
      <div class="title is-6">Edit Hub/Warehouse at Region {{nameHub}}? </div>
      <div class="is-size-6 has-text-weight-semibold has-text-danger">{{msg}}</div>
      <div class="is-flex is-flex-direction-row is-align-items-baseline">
        <!-- <div class="is-size-6 has-text-weight-semibold">Zone Name: </div> -->
        <!-- <div class="is-size-7">{{zone}}</div> -->
      </div>
      <div class="notification is-white">
        <div class="field">
          <label class="label is-small">Hub Type</label>
          <div class="control">
            <div class="select is-small">
              <select v-model="hubType">
                <option value="main" :selected="facility === 'main'">Main</option>
                <option value="sub region" :selected="facility === 'sub region'">Sub Region</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Region</label>
          <div class="control">
            <div class="select is-small">
              <select v-model="regionHub">
                <option value="calgary" :selected="facility === 'calgary'">Calgary</option>
                <option value="edmonton" :selected="facility === 'edmonton'">Edmonton</option>
                <option value="vancouver" :selected="facility === 'vancouver'">Vancouver</option>
                <option value="victoria" :selected="facility === 'victoria'">Victoria</option>
                <option value="kelowna" :selected="facility === 'kelowna'">Kelowna</option>
                <option value="gta" :selected="facility === 'gta'">GTA</option>
                <option value="ottawa" :selected="facility === 'ottawa'">Ottawa</option>
                <option value="london" :selected="facility === 'london'">London</option>
                <option value="quebec" :selected="facility === 'quebec'">Quebec</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Name</label>
          <div class="control">
            <input class="input is-small" v-model="nameHub" type="text" placeholder="Name">
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Facility</label>
          <div class="control">
            <div class="select is-small">
              <select v-model="facility">
                <option value="business" :selected="facility === 'business'">Business</option>
                <option value="warehouse" :selected="facility === 'warehouse'">Warehouse</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Size</label>
          <div class="control">
            <input class="input is-small" v-model="size" type="number" placeholder="Size">
          </div>
        </div>
        <div class="field">
          <label class="label is-small">Hub Index</label>
          <div class="control">
            <input class="input is-small" v-model="hubIndex" type="text" maxlength="4" placeholder="Shortcut name for markers">
          </div>
        </div>
      </div>
      <div class="columns notification is-white">
        <div class="column">
          <button class="button is-danger is-small" @click="removeHub()"> Remove </button>
        </div>
        <div class="column">
          <button class="button is-warning is-small" @click="closeModal()"> Close </button>
        </div>
        <div class="column">
          <button
            class="button is-small is-primary"
            @click="() => {
              saveToFirestore();
            }"
          > Save </button>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <div
      style="display: flex; align-items: center;justify-content: center;flex-direction: column;padding: 0;margin: 0;"
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { hubsCollection } from '../store/fireStore'
import EventHandler from '../EventHandler'

export default {
  components: {
    PulseLoader
  },
  data () {
    return {
      msg: '',
      zone: '',
      loading: false
    }
  },
  props: {
    coordinates: {
      type: Array
    },
    regionHub: {
      type: String
    },
    store: {
      type: Object
    },
    hubId: {
      type: String
    },
    facility: {
      type: String
    },
    hubIndex: {
      type: String
    },
    hubType: {
      type: String
    },
    nameHub: {
      type: String
    },
    size: {
      type: String
    }
  },
  methods: {
    closeModal () {
      EventHandler.$emit('closeModal')
      // this.remove()
      this.close()
    },
    async removeHub () {
      const hubDrawQuery = await hubsCollection
        .where('nameHub', '==', this.nameHub)
        .where('regionHub', '==', this.regionHub)
        .where('facility', '==', this.facility)
        .where('hubIndex', '==', this.hubIndex)
        .where('hubType', '==', this.hubType)
        .get()
      await hubDrawQuery.forEach(function (doc) {
        doc.ref.delete()
      })
    },
    async saveToFirestore () {
      // this.loading = true
      // const randomColor = () => {
      //   const letters = '0123456789ABCDEF'
      //   let color = '#'
      //   for (let i = 0; i < 6; i++) {
      //     color += letters[Math.floor(Math.random() * 16)]
      //   }
      //   return color
      // }
      // const zone = this.zone.trim()

      const hubType = (this.hubType)
      const regionHub = this.regionHub
      const nameHub = this.nameHub
      const facility = (this.facility)
      const size = (this.size)
      const hubIndex = this.hubIndex
      // const color = this.color
      const coordinates = this.coordinates
      console.log('hubIndex: ', hubIndex)
      const query = await hubsCollection.where('hubIndex', '==', hubIndex).get()
      console.log('query: ', query)
      if (regionHub && nameHub && hubType && facility && size && hubIndex) {
        if (query.empty) {
          await hubsCollection.doc(this.hubId).update({
            coordinates: JSON.stringify(coordinates),
            regionHub: regionHub.trim(),
            nameHub,
            hubType,
            facility,
            size,
            hubIndex
          }).then((docRef) => {
            console.log('DOCREF ID: ', docRef)
            this.loading = false
            EventHandler.$emit('closeModal')
            this.close(hubIndex)
            // this.onSave(this.zone, docRef.id)
            this.msg = ''
          }).catch((err) => {
            console.log('ERR: ', err)
          })
        } else {
          this.loading = false
          this.msg = 'Hub name existing'
        }
      } else {
        this.msg = 'Fill up the missing inputs'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet/dist/leaflet.css";
  .custom .leaflet-popup-tip,
  .custom .leaflet-popup-content-wrapper {
      background: #e93434;
      color: #ffffff;
  }
</style>
