import * as Realm from 'realm-web'
import { ObjectId } from 'bson'
import { zonesInfoCollection } from '../store/fireStore'
import { Shape, Zone } from './interface'
import store from '../store'

const mappingToolRealmAppIdForShape = 'mapping-tool-nzsde'
const mappingToolRealmAppIdForPostalCode = 'mapping-tool-postalcode-seouw'
const mappingToolRealmAppIdForFSA = 'fsa-dmhus'
const realmAppForShape = new Realm.App({ id: mappingToolRealmAppIdForShape })
const realmAppForPostalCode = new Realm.App({ id: mappingToolRealmAppIdForPostalCode })
const realmAppForFSA = new Realm.App({ id: mappingToolRealmAppIdForFSA })
const userEmailRealm = process.env.VUE_APP_REALM_APP_USER
const userPassRealm = process.env.VUE_APP_REALM_APP_PASS

const credentials = Realm.Credentials.emailPassword(userEmailRealm, userPassRealm)
const mappingDbAppForShape = realmAppForShape
  .logIn(credentials)
  .then(async(user) => {
    return user?.mongoClient('mongodb-atlas')
  })
  .then(mongoDb => {
    return mongoDb
  })

const mappingDbAppForPostalCode = realmAppForPostalCode
  .logIn(credentials)
  .then(async(user) => {
    return user?.mongoClient('mongodb-atlas')
  })
  .then(mongoDb => {
    return mongoDb
  })

const mappingDbAppForFSA = realmAppForFSA
  .logIn(credentials)
  .then(async(user) => {
    return user?.mongoClient('mongodb-atlas')
  })
  .then(mongoDb => {
    return mongoDb
  })

const mongoDbAppForShape = async () => {
  const environment = store.state.environment
  const mongodb = await mappingDbAppForShape
  const dbName: string = environment === 'installation' ? 'installation' : 'logistics_miele'
  return mongodb.db(dbName)
}

const mongoDbAppForPostalCode = async () => {
  const environment = store.state.environment
  const mongodb = await mappingDbAppForPostalCode
  const dbName: string = environment === 'installation' ? 'installation' : 'logistics_miele'
  return mongodb.db(dbName)
}

/**
 * @returns zones - All shapes with its coordinates
 */
export async function getZones (region: string[]) {
  try {
    const zoneSelectedDoc = region.length > 0
      ? await zonesInfoCollection.where('region', 'in', region).get()
      : await zonesInfoCollection.get()
    const zoneSelected = zoneSelectedDoc.docs.map((doc) => doc.id.trim())
    const mongoApp = await mongoDbAppForShape()
    const zonesDataCollection = await mongoApp.collection('zones').find({
      shapesID: {
        $in: zoneSelected
      }
    })
    const shapes = zonesDataCollection.map((zone) => ({ ...zone, _id: zone._id.toString(), shapeId: zone._id.toString() }))
    // const zonesDoc = await zonesInfoCollection.get()
    const zones = zoneSelectedDoc.docs.map((doc) => ({ shapesID: doc.id.trim(), zoneId: doc.id.trim(), ...doc.data() }))
    const shapeZones = shapes.map((shp: Shape) => {
      const shapeZoneInfo: Zone | undefined = zones.find((z) => z.shapesID === shp.shapesID)
      const shapeZone = { ...shapeZoneInfo, ...shp, zone: shapeZoneInfo?.value }
      return shapeZone
    })
    return shapeZones
  } catch (e) {
    console.error('er:', e)
    throw e
  }
}
/**
 * @param  {[any]} coordinates - coordinates of a shape, format of coordinates are different if its whole or has a hole
 * @param  {string} shapesID - shapesID is the zone ID to assign to a shape
 * @param  {boolean} multi - information if the shape has whole or not
 * @param  {any} fsa? - usually an array of FSA id that assigned to that shape
 */
export async function insertZone (
  coordinates: [any],
  shapesID: string,
  multi: boolean,
  fsa?: [string]
) {
  try {
    const mongoAppForShape = await mongoDbAppForShape()
    const mongoAppForPostalCode = await mongoDbAppForPostalCode()
    // const mongodbForShapes: any = await mappingDbAppForShape
    const zonesCollection = mongoAppForShape.collection('zones')
    const postalCodeCollection = mongoAppForPostalCode.collection('postal_codes')
    const zonesData = {
      coordinates: JSON.stringify(coordinates),
      shapesID,
      multi,
      fsa: fsa || []
    }
    await zonesCollection.insertOne(zonesData)
    await postalCodeCollection.updateMany({
      geometry: {
        $geoWithin: {
          $geometry: {
            type: 'Polygon',
            coordinates: multi ? coordinates : [coordinates]
          }
        }
      }
    }, {
      $set: {
        zone: shapesID
      }
    })
    return true
  } catch (e) {
    console.log('E:', e)
    return false
  }
}
/**
 * @param  {string} coordinates New coordinates of the shape
 * @param  {string} shapeId The selected shape mongodb ID to edit
 * @param  {boolean} multi if shape has hole or not
 * @param  {any} fsa? new FSA assigned to it
 */
export async function editZoneCoordinates (
  data: {
    coordinates: string,
    shapeId: string,
    multi: boolean,
    fsa?: [string]
  }
) {
  try {
    const shapeId = data.shapeId
    const newCoordinates = data.coordinates
    const newMulti = data.multi
    const mongoAppForShape = await mongoDbAppForShape()
    const mongoAppForPostalCode = await mongoDbAppForPostalCode()
    const zonesCollection = mongoAppForShape.collection('zones')
    const postalCodeCollection = mongoAppForPostalCode.collection('postal_codes')
    const oId = new ObjectId(shapeId)
    return zonesCollection.findOne({
      _id: oId
    }).then(async (currentSelectedZone) => {
      const {
        shapesID,
        coordinates: currentZoneCoordinates,
        multi: currentZoneMulti
      } = currentSelectedZone
      const parseCurrentZoneCoordinates = JSON.parse(currentZoneCoordinates)
      return await postalCodeCollection.updateMany({
        geometry: {
          $geoWithin: {
            $geometry: {
              type: 'Polygon',
              coordinates: currentZoneMulti ? parseCurrentZoneCoordinates : [parseCurrentZoneCoordinates]
            }
          }
        }
      }, {
        $set: {
          zone: '',
        }
      }).then(() => {
        return zonesCollection.updateOne({
          _id: oId
        }, {
          $set: {
            coordinates: JSON.stringify(newCoordinates),
            multi: newMulti
          }
        })
      }).then(() => {
        return postalCodeCollection.updateMany({
          geometry: {
            $geoWithin: {
              $geometry: {
                type: 'Polygon',
                coordinates: newMulti ? newCoordinates : [newCoordinates]
              }
            }
          }
        }, {
          $set: {
            zone: shapesID,
          }
        })
      })
    })
  } catch (e) {
    return false
  }
}
/**
 * @param  {string} shapeId - Selected Shape ID to be deleted
 */
export async function deleteZone (shapeId: string) {
  try {
    const mongoAppForShape = await mongoDbAppForShape()
    const mongoAppForPostalCode = await mongoDbAppForPostalCode()
    const zonesCollection = mongoAppForShape.collection('zones')
    const postalCodeCollection = mongoAppForPostalCode.collection('postal_codes')
    const oId = new ObjectId(shapeId)
    const currentSelectedZone = await zonesCollection.findOne({
      _id: oId
    })
    const {
      coordinates: currentZoneCoordinates,
      multi: currentZoneMulti
    } = currentSelectedZone
    const parseCurrentZoneCoordinates = JSON.parse(currentZoneCoordinates)
    await postalCodeCollection.updateMany({
      geometry: {
        $geoWithin: {
          $geometry: {
            type: 'Polygon',
            coordinates: currentZoneMulti ? parseCurrentZoneCoordinates : [parseCurrentZoneCoordinates]
          }
        }
      }
    }, {
      $set: {
        zone: ''
      }
    })
    await zonesCollection.deleteOne({
      _id: oId
    })
    return true
  } catch (e) {
    return false
  }
}
/**
 * @param  {shapeId?:string} shapeId - Selected Shape mongodb ID to edit FSA
 * @param  {any} fsa? - Array of New FSA id of selected Shape
 */
export async function editZoneFSA (
  data: {
    shapeId: string,
    fsa: string[]
  }
) {
  try {
    const shapeId = data.shapeId
    const fsa = data.fsa
    const mongoAppForShape = await mongoDbAppForShape()
    const zonesCollection = mongoAppForShape.collection('zones')
    const oId = new ObjectId(shapeId)
    return zonesCollection.updateOne({
      _id: oId
    }, {
      $set: {
        fsa: fsa
      }
    })
  } catch (e) {
    return false
  }
}
/**
 * @param  {string} newShapesID - New Zone Id to be assign to the shape
 * @param  {string} shapeId - Selected Shape mongodb ID
 */
export async function changeZoneId (newShapesID: string, shapeId: string) {
  try {
    const mongoAppForShape = await mongoDbAppForShape()
    const zonesCollection = mongoAppForShape.collection('zones')
    const oId = new ObjectId(shapeId)
    return zonesCollection.updateMany({
      _id: oId
    }, {
      $set: {
        shapesID: newShapesID
      }
    })
  } catch (e) {
    return false
  }
}
/**
 * @param  {string[]} shapeId - Array of zones ID to be selected
 */
export async function getSelectedZone (shapeId: string[]) {
  try {
    const mongoAppForShape = await mongoDbAppForShape()
    const zonesDataCollection = await mongoAppForShape.collection('zones').find({
      shapesID: { $in: [shapeId] }
    })
    const shapes = zonesDataCollection.map((zone: Zone) => ({ ...zone, _id: zone._id?.toString(), shapeId: zone._id?.toString() }))
    const zonesDoc = await zonesInfoCollection.get()
    const zones = zonesDoc.docs.map((doc) => ({ shapesID: doc.id.trim(), zoneId: doc.id.trim(), ...doc.data() }))
    const shapeZones = shapes.map((shp: Shape) => {
      const shapeZoneInfo = zones.find((z: Zone) => z.shapesID === shp.shapesID)
      const shapeZone = { ...shapeZoneInfo, ...shp }
      return shapeZone
    })
    return shapeZones
  } catch (e) {
    return false
  }
}

export async function getFSA (region: string) {
  const mongoAppForFSA = await mappingDbAppForFSA
  const FSA = await mongoAppForFSA.db('FSA').collection(region).find({})
  return FSA
}

export async function getAllPostalCode () {
  const db = await mongoDbAppForPostalCode()
  const locations = await db.collection('postal_codes').find()
  const mappedLocations = locations.map(l => ({
    country: l.properties.Country,
    postalCode: l.properties.Postcode,
    coordinates: l.geometry.coordinates,
    latitude: l.geometry.coordinates[1],
    longtitude: l.geometry.coordinates[0],
    zone: l.zone || '',
    region: l.region || ''
  }))
  return mappedLocations
}

export async function updatePostalCode (coordinates: any, zoneId: string, isMulti: boolean) {
  const db = await mongoDbAppForPostalCode()
  const postalCodes = await db.collection('postal_codes').find({
    geometry: {
      $geoWithin: {
        $geometry: {
          type: 'Polygon',
          coordinates: isMulti ? coordinates : [coordinates]
        }
      }
    }
  })
  console.log('postalCodes: ', postalCodes)
  await db.collection('postal_codes').updateMany({
    geometry: {
      $geoWithin: {
        $geometry: {
          type: 'Polygon',
          coordinates: isMulti ? coordinates : [coordinates]
        }
      }
    }
  }, {
    $set: {
      zone: zoneId
    }
  })
}

// this function is for uploading. ONLY FOR DEV
export async function insertZoneMiele (
  coordinates: string,
  shapesID: string,
  multi: boolean,
  fsa?: any
) {
  try {
    const mongoAppForShape = await mongoDbAppForShape()
    // const mongodbForPostalCodes: any = await mappingDbAppForPostalCode
    const zonesCollection = mongoAppForShape.collection('zones')
    // const postalCodeCollection = mongodbForPostalCodes.db('test').collection('test_sample_data')
    const zonesData = {
      coordinates: JSON.stringify(coordinates),
      shapesID,
      multi,
      fsa: fsa || []
    }

    await zonesCollection.insertOne(zonesData)
    // await postalCodeCollection.updateMany({
    //   geometry: {
    //     $geoWithin: {
    //       $geometry: {
    //         type: 'Polygon',
    //         coordinates: multi ? coordinates : [coordinates]
    //       }
    //     }
    //   }
    // }, {
    //   $set: {
    //     zone: shapesID
    //   }
    // })
    return true
  } catch (e) {
    console.log('E:', e)
    return false
  }
}
