import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DB,
  projectId: process.env.VUE_APP_FIREBASE_PID,
  storageBucket: process.env.VUE_APP_FIREBASE_SB,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MSI,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

// optimizer routes
const routingConfig = {
  apiKey: 'AIzaSyDGPd4v56XggEEtXV6b58u4X9lHHRywLnU',
  authDomain: 'shyft-optimizer.firebaseapp.com',
  databaseURL: 'https://shyft-optimizer.firebaseio.com',
  projectId: 'shyft-optimizer',
  storageBucket: 'shyft-optimizer.appspot.com',
  appId: '1:680605399933:web:4c5d4bfc5fc902e1ee2a14'
}

firebase.initializeApp(firebaseConfig)
firebase.initializeApp(routingConfig, 'routing')

export const db = firebase.firestore()

export const fleetSettings = db.collection('FLEET_SETTINGS')
export const fleet = db.collection('FLEET')
export const shapesCollection = db.collection('SHAPES')
export const hubsCollection = db.collection('HUBS')
export const FSACollection = db.collection('FSA')
export const zonesInfoCollection = db.collection('RATE_TABLES_ZONES')
export const getDeliveries = firebase.functions(firebase.app('routing')).httpsCallable('OPTIMIZER-getRoutes')
