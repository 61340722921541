<template>
  <div>
    <div v-if="!loading">
      <div class="title is-6">Save Zone at Region {{region}}? </div>
      <div class="is-size-6 has-text-weight-semibold has-text-danger">{{msg}}</div>
      <div class="is-flex is-flex-direction-row is-align-items-baseline">
        <!-- <div class="is-size-6 has-text-weight-semibold">Zone Name: </div> -->
        <!-- <div class="is-size-7">{{zone}}</div> -->
      </div>
      <div class="notification is-white">
        <!-- <input class="input is-small" type="text" v-model="zone" placeholder="Insert zone name"/> -->
          <multiselect
            v-model="zone"
            :options="zoneList"
            label="value"
            :close-on-select="true"
            track-by="value"
          ></multiselect>
      </div>
      <div class="columns notification is-white">
        <div class="column"></div>
        <div class="column has-text-centered">
          <button class="button is-warning is-small" @click="closeModal()"> Cancel </button>
        </div>
        <div class="column">
          <button
            class="button is-small is-primary"
            @click="() => {
              saveToFirestore();
            }"
          > Save </button>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <div
      style="display: flex; align-items: center;justify-content: center;flex-direction: column;padding: 0;margin: 0;"
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import store from '../store'
import { insertZone } from '../store/mongoStore'
import EventHandler from '../EventHandler'

export default {
  components: {
    PulseLoader,
    Multiselect
  },
  data () {
    return {
      msg: '',
      zone: {
        value: '',
        zoneId: ''
      },
      loading: false
    }
  },
  props: {
    coordinates: {
      type: Array
    },
    region: {
      type: String
    }
  },
  computed: {
    zoneList () {
      const currentRegion = store.state.region.trim()
      return store.state.zoneInformations.filter(zone => zone.region === currentRegion)
    }
  },
  methods: {
    closeModal () {
      EventHandler.$emit('closeModal')
      // this.remove()
      this.close()
    },
    async testFireStore () {
      const zone = this.zone.value ? this.zone.value.trim() : ''
      const zoneNameId = this.zone.id ? this.zone.id.trim() : ''
      const coordinates = this.coordinates
      const region = this.region.trim()

      console.log('zone: ', zone)
      console.log('zoneNameId: ', zoneNameId)
      console.log('coordinates: ', coordinates)
      console.log('region: ', region)
    },
    async saveToFirestore () {
      this.loading = true
      const zone = this.zone.value ? this.zone.value.trim() : ''
      const zoneId = this.zone.id ? this.zone.id.trim() : ''
      const coordinates = this.coordinates
      if (zone && zoneId) {
        try {
          await insertZone(coordinates, zoneId, false)
          this.loading = false
          EventHandler.$emit('closeModal')
          this.close()
          this.msg = ''
          await this.updateMap()
        } catch (err) {
          this.loading = false
          this.msg = 'Error adding'
        }
      } else {
        this.loading = false
        this.msg = 'Please select name'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "~leaflet/dist/leaflet.css";
  .custom .leaflet-popup-tip,
  .custom .leaflet-popup-content-wrapper {
      background: #e93434;
      color: #ffffff;
  }
</style>
