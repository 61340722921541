<template>
  <div>
    <div class="" v-if="!loading">
      <md-list class="md-double-line">
        <div class="title is-5">Edit Zone(FSA SelectedMode):</div>
        <div class="is-size-6 has-text-weight-semibold has-text-danger">{{msg}}</div>
        <div class="is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-baseline">
          <div class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold">Zone: {{shape.zone}}</div>
          <div class="is-size-6 is-flex is-flex-direction-row has-text-weight-semibold">Region: </div>
          <div class="is-size-6">{{shape.region}}</div>
        </div>
        <div class="notification is-white">
          <!-- <input class="input is-small" type="text" v-model="zone" placeholder="Insert new zone name" /> -->
        </div>
        <div class="columns notification is-white">
          <!-- <div class="column has-text-centered">
            <button
              class="button is-warning is-small"
              @click="closeModal()"
            > Close </button>
          </div> -->
          <div class="column has-text-centered">
            <button
              class="button is-info is-small"
              @click="cancelEdit()"
            > Cancel </button>
          </div>
          <div class="column has-text-centered">
            <button
              class="button is-small is-primary"
              @click="editFirestore"
            > Save </button>
          </div>
        </div>
      </md-list>
    </div>
    <div
      style="display: flex; align-items: center;justify-content: center;flex-direction: column;padding: 0;margin: 0;"
      v-else
    >
      <PulseLoader />
    </div>
  </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { editZoneCoordinates, editZoneFSA, insertZone, deleteZone } from '../store/mongoStore'
import EventHandler from '../EventHandler'

const SINGLE_SHAPE = 'SINGLE_SHAPE'
const SINGLE_SHAPE_WITH_HOLES = 'SINGLE_SHAPE_WITH_HOLES'
const MULTI_SINGLE_SHAPE = 'MULTI_SINGLE_SHAPE'
// const MULTI_SINGLE_WITH_HOLES = 'MULTI_SINGLE_WITH_HOLES'

export default {
  components: {
    PulseLoader
  },
  data () {
    return {
      msg: '',
      loading: false
    }
  },
  props: {
    shapesToAdd: {
      type: [Array, Object]
    },
    shape: {
      type: Object
    },
    shapeType: {
      type: String
    }
  },
  methods: {
    closeModal () {
      EventHandler.$emit('closeModal')
      this.close()
      this.editNameMode = false
      // console.log('CLOSE')
      // this.remove()
      // this.test()
    },
    cancelEdit () {
      this.cancelEdit()
    },
    async saveCoordinates (shapesToAdd, shapeId, shapeType) {
      if (shapeType === SINGLE_SHAPE) {
        const coordinates = shapesToAdd.coordinates[0]
        const fsa = shapesToAdd.fsa
        try {
          await editZoneCoordinates({
            coordinates,
            shapeId,
            multi: false
          })
          await editZoneFSA({
            shapeId,
            fsa
          })
          await this.updateMap()
          await this.removeShape()
          this.loading = false
        } catch (err) {
          this.loading = false
          this.msg = 'Error in editing zone'
        }
      } else if (shapeType === SINGLE_SHAPE_WITH_HOLES) {
        const coordinates = shapesToAdd.coordinates
        const fsa = shapesToAdd.fsa
        try {
          await editZoneCoordinates({
            coordinates,
            shapeId,
            multi: true
          })
          await editZoneFSA({
            shapeId,
            fsa
          })
          await this.updateMap()
          await this.removeShape()
          this.loading = false
        } catch (err) {
          this.loading = false
          this.msg = 'Error in editing zone'
        }
      } else if (shapeType === MULTI_SINGLE_SHAPE) {
        const selectedShape = this.shape
        const zoneId = selectedShape.zoneId
        await Promise.all(shapesToAdd.map(async (shape) => {
          const coordinates = shape.coordinates
          const fsa = shape.fsa
          return insertZone(
            coordinates,
            zoneId,
            false,
            fsa
          )
        }))
        await deleteZone(selectedShape.id)
        await this.updateMap()
        await this.removeShape()
      } else {
        const selectedShape = this.shape
        const zoneId = selectedShape.zoneId
        await Promise.all(shapesToAdd.map(async (shape) => {
          const coordinates = shape.coordinates
          const fsa = shape.fsa
          if (coordinates.length > 1) {
            return insertZone(
              coordinates,
              zoneId,
              true,
              fsa
            )
          } else {
            return insertZone(
              coordinates[0],
              zoneId,
              false,
              fsa
            )
          }
        }))
        await deleteZone(selectedShape.id)
        await this.updateMap()
        await this.removeShape()
      }
    },
    async editFirestore () {
      this.loading = true
      const shapeType = this.shapeType.trim()
      const shapeId = this.shape.id.trim()
      const shapesToAdd = this.shapesToAdd
      // const coordinates = hasHoles ? this.coordinates : this.coordinates[0]

      this.saveCoordinates(shapesToAdd, shapeId, shapeType)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  @import "~leaflet/dist/leaflet.css";
  .custom .leaflet-popup-tip,
  .custom .leaflet-popup-content-wrapper {
      background: #e93434;
      color: #ffffff;
  }
</style>
