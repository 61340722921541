import Vue from 'vue'
import EditMarkerZoneModal from '../../components/EditMarkerZoneModal.vue'

export default function EditMarker (
  store: any,
  layerID: any,
  editHubDrawnzoneFunction: any,
  map: any,
  coordinates: any,
  hubId: any,
  facility: any,
  hubIndex: any,
  hubType: any,
  nameHub: any,
  regionHub: any,
  size: any
) {
  const ComponentClass = Vue.extend(EditMarkerZoneModal)
  const instance = new ComponentClass({
    propsData: {
      store: store,
      coordinates: coordinates,
      hubId: hubId,
      facility,
      hubIndex,
      hubType,
      nameHub,
      regionHub,
      size
    },
    methods: {
      editZone: (newZoneName: any, oldZoneName: any) => {
        editHubDrawnzoneFunction(newZoneName, layerID, oldZoneName)
      },
      close: () => {
        map.closePopup()
      }
    }
  })

  return instance
}
