import Vue from 'vue'
import EditShapeModal from '../../components/EditShapeModal.vue'

export default function EditShape (
  store: any,
  coordinates: any,
  shape: any,
  vehicles: any,
  editZoneDrawnzoneFunction: (a: string, b: string, c: string) => {},
  layerID: string,
  map: any,
  addMoreShapeFunc: (a: boolean) => {},
  renderMapFunc: () => {},
  selectedFSAModeEdit: () => {},
  closeEditCurrentShapeMode: () => {}
) {
  const ComponentClass = Vue.extend(EditShapeModal)
  const instance = new ComponentClass({
    propsData: {
      store,
      coordinates,
      shape,
      vehicles
    },
    methods: {
      editZone: (newZoneName: string, oldZoneName: string) => {
        editZoneDrawnzoneFunction(newZoneName, layerID, oldZoneName)
      },
      close: () => {
        closeEditCurrentShapeMode()
        map.closePopup()
      },
      addMoreShapeFunc: (toAdd: boolean) => {
        addMoreShapeFunc(toAdd)
      },
      renderMapFunc: async () => {
        setTimeout(async () => {
          await renderMapFunc()
        }, 1000)
      },
      selectedFSAModeEdit: async () => {
        await selectedFSAModeEdit()
      }
    }
  })

  return instance
}
