
<template>
  <div>
    <div class="map-container" v-bind:style="mapContainerStyling">
      <div class="fit">
        <!--------------------------  TOOLBAR ---------------------------->
        <div
          class="level has-background-grey-dark"
          style="padding: 8px;margin-bottom: 0;"
        >
          <div class="level-left">
            <!-- <button
              class="level-item button is-outlined is-small is-light is-rounded"
            >
              Driver</button> -->
            <div class="select is-small is-rounded">
              <select
                style="width:120px;"
                @change="selectRegion"
                :disabled="retrievingFSA"
              >
                <option
                  v-for="(option, index) in regions"
                  :selected="option == $store.state.region"
                  :key="index"
                  :value="option"
                  >{{ option }}</option
                >
              </select>
            </div>
            <button
              v-bind:class="
                importingLoading ? importButtonLoading : importButtonNotLoading
              "
              @click="exportPdf"
            >
              Export Excel
            </button>
            <!-- <button
              v-bind:class="importButtonNotLoading"
              @click="setPostalCode"
            >
              Insert Postal Codes
            </button> -->
            <button
              v-if="!selectableFSAMode && !editSelectableFSAMode && !FSAConfirmMode"
              v-bind:class="zoneButton"
              @click="showZoneListModal = true"
            >
              Zones
            </button>
            <button
              v-if="!selectableFSAMode && !editSelectableFSAMode && !FSAConfirmMode"
              v-bind:class="refreshLoading ? refreshButtonLoading : refreshButton"
              @click="refresh"
            >
              Refresh Map
            </button>
            <button
              v-bind:class="FSASelectableMode"
              v-if="!selectableFSAMode && (!addMoreShapeMode && !deletePartShapeMode) && !FSAConfirmMode"
              @click="selectableFSAModeOn(false)"
            >
              FSA Mode
            </button>
            <button
              v-bind:class="FSASelectableMode"
              v-if="selectableFSAMode"
              @click="closeSelectableFSAMode(false)"
            >
              Close FSA Mode
            </button>
            <button
              v-bind:class="FSASelectableMode"
              v-if="
                selectableFSAMode &&
                  selectedFSAToAdd.length > 0 &&
                  !editSelectableFSAMode
              "
              @click="openAddFSAShapeZoneModal"
            >
              Confirm FSA Zones
            </button>
            <button
              v-bind:class="FSASelectableMode"
              v-if="
                selectableFSAMode &&
                  selectedFSAToAdd.length > 0 &&
                  editSelectableFSAMode
              "
              @click="openEditFSAShapeZoneModal"
            >
              Confirm Edit FSA Zones
            </button>
            <!-- COMMENT THIS WHEN PRODUCTION -->
            <!-- <button
              class="button is-small is-info level-item is-rounded"
              @click="generateZone"
            >
                Generate Zone
            </button> -->
            <!-- COMMENT THIS WHEN PRODUCTION -->
            <!-- <template v-if="!addMoreShapeMode && !deletePartShapeMode">
              <button
                v-if="
                  selectedShape && !selectableFSAMode && !editSelectableFSAMode
                "
                class="button is-small is-warning level-item is-rounded"
                @click="closeEditCurrentShapeMode()"
              >
                Unselect zone
              </button>
              <button
                v-if="
                  selectedShape && !selectableFSAMode && !editSelectableFSAMode
                "
                :class="overlapLoading ? removeOverlapsButtonLoading : removeOverlapsButtonNotLoading"
                @click="removeOverlapZones()"
              >
                Remove Overlaps
              </button>
            </template> -->
            <button
              v-if="addMoreShapeMode"
              class="button is-small is-info level-item is-rounded"
              @click="closeAddDeletePartShapeMode()"
            >
              Close Add More Shape Mode
            </button>
            <button
              v-if="deletePartShapeMode"
              class="button is-small is-info level-item is-rounded"
              @click="closeAddDeletePartShapeMode()"
            >
              Close Delete Parts Shape Mode
            </button>
          </div>
          <div class="level-right">
            <button
              class="level-item button is-outlined is-small is-light is-rounded"
              @click="toggleVehicleSidebar"
            >
              Toggle Vehicle
            </button>
            <!-- <button
              class="button is-small is-warning level-item is-rounded"
            >changeRegion</button> -->
          </div>
        </div>
        <!--------------------------  TOOLBAR - end ---------------------------->
        <div class="is-flex">
          <!-------------------------- MAP ---------------------------->
          <l-map
            ref="map"
            v-bind:style="mapStyling"
            :zoom="zoom"
            :maxZoom="maxZoom"
            :center="center"
            :options="mapOptions"
            @update:zoom="zoomUpdated"
            @update:bounds="boundsUpdated"
          >
            <l-tile-layer :url="url"></l-tile-layer>
          </l-map>
          <div v-bind:style="toolbarStyling">
            <Vehicles
              :showVehicleModal="showVehicleModal"
              :regions="regions"
              :zones="zones"
              :vehicleObj="vehicleObj"
              @closeModal="showVehicleModal = false"
              v-if="mappingLoaded && !minimizeSideBar"
            />
          </div>
          <!-------------------------- MAP - end ---------------------------->
        </div>
      </div>
    </div>
    <notifications group="fsa" position="bottom left" />
    <notifications group="zones" position="bottom right" />
    <ZoneList
      @renderMap="renderZonesToMap"
      :showZoneListModal="showZoneListModal"
      @closeModal="showZoneListModal = false"
    />
    <ChangeRegionModal
      :showChangingRegionModal="showChangingRegionModal"
      @closeModal="showChangingRegionModal = false"
    />
  </div>
</template>

<script>

// import { LMap, LTileLayer, LMarker, Draw, featureGroup, Map, LatLng } from 'vue2-leaflet'
import Vue from 'vue'
import { LMap, LTileLayer } from 'vue2-leaflet'
// import openGeocoder from 'node-open-geocoder'
import { Icon } from 'leaflet'
import { mapState, mapActions } from 'vuex'
import centerMass from '@turf/center-of-mass'
import difference from '@turf/difference'
import { polygon as polygonTurf, union } from '@turf/turf'
import {
  intersection as intersectionClipping,
  difference as differenceClipping,
} from 'polygon-clipping'
import AddShapeModal from '@/components/AddShapeModal.vue'
import AddMarkerZoneModal from '@/components/AddMarkerZoneModal.vue'
import AddFSAZoneModal from '@/components/AddFSAZoneModal.vue'
import EditFSAZoneModal from '@/components/EditFSAZoneModal.vue'
import EditShape from '../utils/components/EditShape'
import EditMarker from '../utils/components/EditMarker'
import {
  fleetSettings,
  hubsCollection,
  zonesInfoCollection
} from '../store/fireStore'

import {
  getZones,
  insertZone,
  editZoneCoordinates,
  deleteZone,
  editZoneFSA,
  getFSA,
  getAllPostalCode,
  updatePostalCode,
  insertZoneMiele
} from '../store/mongoStore'

import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-toolbar'
import 'leaflet.markercluster'

// const db = firebase.firestore()

const randomColor = () => {
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const polygonIntersects = (coordinate1, coordinate2) => {
  try {
    const poly1 = coordinate1.every((cood) => cood.length === 2)
      ? [coordinate1]
      : coordinate1
    const poly2 = coordinate2.every((cood) => cood.length === 2)
      ? [coordinate2]
      : coordinate2
    const intersection = intersectionClipping(poly1, poly2)
    return intersection.length > 0
  } catch (err) {
    // console.error(err)
    // console.log('coordinate1:', coordinate1)
    // console.log('coordinate1:', coordinate2)
    // throw err
    return false
  }
}

const regionCenter = (region) => {
  const regionName = region.toLowerCase().trim()
  switch (regionName) {
    case 'calgary':
      return [51.05011, -114.08529]
    case 'edmonton':
      return [53.55014, -113.46871]
    case 'vancouver':
      return [49.24966, -123.11934]
    case 'toronto':
      return [43.70011, -79.4163]
    case 'quebec':
      return [46.81228, -71.21454]
    case 'ottawa':
      return [45.424721, -75.695]
    case 'kelowna':
      return [49.882114, -119.477829]
    case 'victoria':
      return [48.407326, -123.329773]
    case 'newfoundland':
      return [53.028019, -59.435242]
    case 'novascotia':
      return [44.681988, -63.744312]
    case 'princeedwardisland':
      return [46.508160, -63.599717]
    case 'newbrunswick':
      return [46.498390, -66.159668]
    case 'winnipeg':
      return [49.895077, -97.138451]
    case 'regina':
      return [50.445210, -104.618896]
    // case 'nunavut':
    //   return [70.453262, -86.798981]
    default:
      return [45.424721, -75.695]
  }
}

const getProvince = (region) => {
  const regionName = region.toLowerCase().trim()
  switch (regionName) {
    case 'calgary':
    case 'edmonton':
      return 'AB'
    case 'toronto':
    case 'ottawa':
      return 'ON'
    case 'vancouver':
    case 'kelowna':
    case 'victoria':
      return 'BC'
    case 'quebec':
      return 'QC'
    case 'newfoundland':
      return 'NL'
    case 'novascotia':
      return 'NS'
    case 'princeedwardisland':
      return 'PE'
    case 'newbrunswick':
      return 'NB'
    case 'winnipeg':
      return 'MB'
    case 'regina':
      return 'SK'
    // case 'nunavut':
    //   return 'NU'
    default:
      return 'AB'
  }
}

const getProvinceCities = (region) => {
  const regionName = region.toLowerCase().trim()
  switch (regionName) {
    case 'calgary':
    case 'edmonton':
      return ['calgary', 'edmonton']
    case 'toronto':
    case 'ottawa':
      return ['toronto', 'ottawa']
    case 'vancouver':
    case 'kelowna':
    case 'victoria':
      return ['vancouver', 'kelowna', 'victoria']
    case 'quebec':
      return ['quebec']
    case 'newfoundland':
      return ['newfoundland']
    case 'novascotia':
      return ['novascotia']
    case 'princeedwardisland':
      return ['princeedwardisland']
    case 'newbrunswick':
      return ['newbrunswick']
    case 'winnipeg':
      return ['winnipeg']
    case 'regina':
      return ['regina']
    // case 'nunavut':
    //   return ['nunavut']
    default:
      return []
  }
}

export default {
  name: 'Map',
  components: {
    Vehicles: () => import('@/components/Vehicles.vue'),
    ZoneList: () => import('@/components/ZoneListModal.vue'),
    ChangeRegionModal: () => import('@/components/ChangeRegionModal'),
    LMap,
    LTileLayer
  },
  data () {
    return {
      mappingLoaded: false,
      importingLoading: false,
      refreshLoading: false,
      overlapLoading: false,
      showVehicleModal: false,
      showZoneListModal: false,
      showChangingRegionModal: false,
      url: `https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${process.env.VUE_APP_STADIA_API}`,
      zoom: 10,
      maxZoom: 20,
      center: [45.203427, -76.016414],
      bounds: null,
      map: null,
      coordinatesStr: '',
      postcode: '',
      displayName: '',
      locations: '',
      pointsDrawn: 0,
      selectedRegionIndex: 0,
      drawnZones: [],
      drawnMarkers: [],
      drawnFSA: [],
      zones: [],
      markers: [],
      regions: [],
      editedZones: [],
      vehicles: [],
      vehicleObj: {},
      drawControl: null,
      markerGroup: null,
      addDeletePartShapeModeDrawControl: null,
      FSALayerGroup: null,
      FSANameLayerGroup: null,
      FSALayerControl: null,
      retrievingFSA: false,
      minimizeSideBar: true,
      drawnItems: null,
      FSAItems: null,
      selectedFSAToAdd: [],
      toolbarStyling: {
        width: '0vw'
      },
      mapStyling: {
        height: '95vh',
        width: '100vw'
      },
      mapContainerStyling: {
        position: 'initial'
      },
      mapOptions: {
        // preferCanvas: true
      },
      importButtonLoading:
        'mx-3 button is-small is-warning level-item is-rounded is-loading',
      importButtonNotLoading:
        'mx-3 button is-small is-warning level-item is-rounded',
      zoneButton: 'button is-small is-info level-item is-rounded',
      refreshButton: 'button is-small level-item is-rounded is-dark',
      refreshButtonLoading: 'button is-small level-item is-rounded is-dark is-loading',
      FSASelectableMode:
        'button is-small level-item is-rounded is-primary is-light',
      removeOverlapsButtonLoading:
        'button is-small is-danger is-light level-item is-rounded is-loading',
      removeOverlapsButtonNotLoading:
        'button is-small is-danger is-light level-item is-rounded',
      selectedShape: null,
      currentSelectedFSAShape: [],
      toBeSaveShapeFSAID: null,
      editShapeSelectedMode: false,
      addMoreShapeMode: false,
      deletePartShapeMode: false,
      selectableFSAMode: false,
      editSelectableFSAMode: false,
      FSAConfirmMode: false
    }
  },
  methods: {
    ...mapActions(['toggleStopCell', 'initDeliveryData']),
    zoomUpdated (zoom) {
      this.zoom = zoom
      this.mapContainerStyling = {
        position: 'initial'
      }
    },
    boundsUpdated (bounds) {
      this.bounds = bounds
      this.mapContainerStyling = {
        position: 'initial'
      }
    },
    editZoneDrawnzone (newZoneName, oldZone) {
      const regions = this.regions
      regions.map(async (reg, index) => {
        const selectedTruck = this.vehicleObj[this.regions[index]]
          .map((t, index) => ({ ...t, truckIndex: index }))
          .filter((truck) => truck.zone === oldZone)
        selectedTruck.map((truck) => {
          const payload = {
            value: newZoneName,
            key: 'zone',
            index: truck.truckIndex,
            region: reg
          }
          this.$store.dispatch('setTruckFleetSetting', payload)
        })
        await fleetSettings
          .doc(`${reg}`)
          .set({ fleet: this.$store.state.fleetSettings[reg] })
      })
    },
    /**
    * @param  {e} event - value of selected region
    */
    async selectRegion (e) {
      const oldRegion = this.$store.state.region
      const newRegion = e.target.value
      const selectedFleet = this.vehicleObj[newRegion]
      this.vehicles = selectedFleet
      this.$store.dispatch('setRegion', newRegion)
      this.$store.dispatch('setSelectedVehicles', selectedFleet)
      this.$store.dispatch('setTrucksToBeAssigned', [])
      this.center = regionCenter(newRegion)
      this.zones = this.drawnZones.filter(
        (shape) => shape.region === this.$store.state.region
      )

      // this is to avoid getting the fsa of the same province
      if (getProvince(newRegion) !== getProvince(oldRegion)) {
        this.showChangingRegionModal = true
        this.map.removeLayer(this.FSALayerGroup)
        this.map.removeLayer(this.FSANameLayerGroup)
        this.map.removeControl(this.FSALayerControl)
        await this.closeSelectableFSAMode(true)
        setTimeout(async () => {
          await this.renderZonesToMap(true)
          await this.renderFSAToMap(getProvince(newRegion))
        }, 500)
      }
      // if (newRegion !== oldRegion) {
      //   setTimeout(async () => {
      //     await this.renderZonesToMap(true)
      //   }, 1000)
      // }
    },
    /**
    * To show or hide the Fleet settings bar
    */
    toggleVehicleSidebar () {
      if (this.minimizeSideBar) {
        this.toolbarStyling = {
          width: '30vw'
        }
        this.mapStyling = {
          height: '95vh',
          width: '70vw'
        }
      } else {
        this.toolbarStyling = {
          width: '0vw'
        }
        this.mapStyling = {
          height: '95vh',
          width: '100vw'
        }
      }
      this.minimizeSideBar = !this.minimizeSideBar
    },
    /**
    * Export PDF of Postal Codes Data
    */
    async exportPdf () {
      this.importingLoading = true
      const postalCodes = await getAllPostalCode()
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = [
          'Postal Code',
          'Zone',
          'Region',
          'Country',
          'Latitude',
          'Longtitude'
        ]
        const filterVal = [
          'postalCode',
          'zone',
          'region',
          'country',
          'latitude',
          'longtitude'
        ]
        const data = this.formatJson(filterVal, postalCodes)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'zones-postalcode',
          autoWidth: this.autoWidth,
          bookType: this.bookType
        })
      })
      this.importingLoading = false
    },
    exportPdfMessage () {
      this.$notify({
        group: 'fsa',
        title: 'Feature not live',
        closeOnClick: true,
        duration: 3000,
        text: 'This functionality will be available in the live environment'
      })
    },
    /**
    * Format JSON for printable to PDF
    */
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j]
        })
      )
    },
    /**
    * Return Icon indicator for hubs
    */
    getIcon (orderTypeShort, color, hubIndex) {
      return window.L.divIcon({
        className: 'my-custom-pin',
        html:
          orderTypeShort === 'H'
            ? `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 20 34" height="45" width="45" enable-background="new 0 0 18 18" style="fill:${color};"><g><polygon points="16,5 9,1 2,5 2,17 4,17 4,7 14,7 14,17 16,17  "/><rect x="5" y="15" width="2" height="2"/><rect x="8" y="15" width="2" height="2"/><rect x="11" y="15" width="2" height="2"/><rect x="5" y="12" width="2" height="2"/><rect x="8" y="12" width="2" height="2"/><rect x="11" y="12" width="2" height="2"/><rect x="5" y="9" width="2" height="2"/><rect x="8" y="9" width="2" height="2"/></g></svg>`
            : `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 34.892337" height="35" width="35">
                <g transform="translate(-814.59595,-274.38623)">
                  <g transform="matrix(1.1855854,0,0,1.1855854,-151.17715,-57.3976)">
                    <path d="m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z" style="fill:${color};stroke:${color};"/>
                    <circle r="7.0355" cy="288.25278" cx="823.03064" style="display:inline;fill: #FFF"/>
                  </g>
                </g>
                <text text-anchor="middle" stroke="#4A4A4A" x="50%" y="40%" style="font-size:0.6rem">${hubIndex}</text>
              </svg>`
      })
    },
    async setPostalCode () {
      const selectedShape = this.selectedShape
      console.log('selectedShape:', selectedShape)
      console.log('selectedShape.invertedCoordinates:', selectedShape.invertedCoordinates)
      console.log('selectedShape.zoneId:', selectedShape.zoneId)
      console.log('selectedShape.multi:', selectedShape.multi)
      updatePostalCode(selectedShape.invertedCoordinates, selectedShape.zoneId, selectedShape.multi)
    },
    /**
    * FOR DEVELOPMENT and Miele
    * To Insert files that consist of zones coming from miele
    */
    async generateZone () {
      // For AB for now
      // uploading miele ZONES For AB
      const currentRegion = this.$store.state.region
      // mieleZones.forEach((province) => {
      // const provinceName = province.zones
      const zones = [
        {
          name: 'LTL',
          color: 'red'
        }
        // {
        //   name: '420',
        //   color: 'blue'
        // }
        // {
        //   name: '440',
        //   color: 'yellow'
        // }
        // {
        //   name: '450',
        //   color: 'orange'
        // }
        // {
        //   name: '650',
        //   color: 'violet'
        // }
      ]
      const provinceName = 'QC'
      zones.forEach(async (zone) => {
        const zoneName = zone.name
        const color = zone.color
        zonesInfoCollection
          .add({
            color: color,
            region: currentRegion.trim(),
            value: `${zoneName}-QC`,
            key: `${zoneName}-QC`,
            max: 0,
            min: 0,
            selected: false,
            environment: 'logistics'
          })
          .then((res) => {
            const zoneId = res.id
            // list of coordinates of fsa in that zone
            let fsaSelected = []
            const fsaSelectedToPolygon = []
            const FSAzones =
              require(`../../FSA/${provinceName}/${zoneName}`) ||
              []
            Promise.all(require(`../../FSA/${provinceName}`))
              // Promise.all(require(`../../FSA/FSAZonesCode/${provinceName}/${zoneName}FSA`))
              .then(async (res) => {
                const fsaLocations = res
                fsaLocations.map((shape) => {
                  if (FSAzones.includes(shape.FSA)) {
                    fsaSelected.push(shape)
                  }
                })
                fsaSelected = fsaSelected.map((fs) => {
                  return {
                    FSA: fs.FSA,
                    region: fs.province,
                    FSA_ID: fs.FSA_ID,
                    coordinates: JSON.parse(fs.coordinates)
                  }
                })
                return Promise.resolve()
              })
              .then(async () => {
                // union
                fsaSelected.forEach((fs) => {
                  const polygon = polygonTurf([fs.coordinates])
                  fsaSelectedToPolygon.push(polygon)
                })
                // console.log('fsaSelected: ', fsaSelected)
                // fsaSelected.forEach(async (fsa) => {
                //   fsaIDSSelected.push(fsa.FSA_ID)
                //   // await console.log('fsa.FSA_ID: ', fsa.FSA_ID)
                //   // const FSARef = await FSACollection.doc(fsa.FSA_ID).get()
                //   // awaitconsole.log('FSARef: ', FSARef)
                //   // 'FSACollection'
                // })
                return Promise.resolve()
              })
              .then(async () => {
                const combinedPolygon = union(...fsaSelectedToPolygon)
                // const invertCombineCoordinates = combinedPolygon.geometry.coordinates.map(
                //   (shape) => {
                //     return shape[0].map((c) => [c[1], c[0]])
                //   }
                // )
                const zoneShapes = combinedPolygon.geometry.coordinates
                console.log('zoneShapes:', zoneShapes)
                if (!zoneShapes.every(shape => shape.every(shp => shp.every(cood => cood.length === 2)))) {
                  console.log('not every')
                  zoneShapes.forEach((shape) => {
                    // const zoneShape = polygonTurf(shape)
                    const shapeCoordinates = shape.map((c) => [c[0], c[1]])
                    // const shapeInvertedCoordinates = shape[0].map((c) => [c[1], c[0]])
                    // const color = randomColor()
                    console.log('shapeCoordinates:', shapeCoordinates)
                    // console.log('shapeInvertedCoordinates: ', shapeInvertedCoordinates)
                    const fsaIDSSelected = []
                    this.drawnFSA.forEach(async (fsa) => {
                      if (polygonIntersects(
                        shapeCoordinates,
                        fsa.invertedCoordinates,
                      )) {
                        fsaIDSSelected.push(fsa.FSA_ID)
                      }
                    })
                    // console.log('color: ', color)
                    // console.log('fsaIDSSelected: ', fsaIDSSelected)
                    // console.log('shapeCoordinates: ', shapeCoordinates)
                    // const polygon = window.L.polygon(shapeInvertedCoordinates, {
                    //   color: color,
                    //   weight: 1,
                    //   fill: true,
                    //   fillColor: color,
                    //   fillOpacity: 0.3
                    //   // pane: 'tilePane'
                    // }).addTo(this.map)
                    // polygon.bindTooltip(`<div> ${color} </div>`, {
                    //   permanent: true,
                    //   direction: 'center',
                    //   opacity: '1'
                    // })
                    const multi = shape.length > 1
                    insertZoneMiele(shapeCoordinates, zoneId, false, fsaIDSSelected)
                  })
                } else {
                  console.log('every')
                  zoneShapes.forEach((shape) => {
                    // const zoneShape = polygonTurf(shape)
                    console.log('shape:', shape)
                    const shapeCoordinates = shape.length === 1
                      ? shape[0].map((c) => [c[0], c[1]])
                      : shape.map(shp => shp.map((c) => [c[0], c[1]]))
                    // const shapeInvertedCoordinates = shape[0].map((c) => [c[1], c[0]])
                    // const color = randomColor()
                    // console.log('shapeInvertedCoordinates: ', shapeInvertedCoordinates)
                    const fsaIDSSelected = []
                    this.drawnFSA.forEach(async (fsa) => {
                      if (polygonIntersects(
                        shapeCoordinates,
                        fsa.invertedCoordinates,
                      )) {
                        fsaIDSSelected.push(fsa.FSA_ID)
                      }
                    })
                    // console.log('color: ', color)
                    // console.log('fsaIDSSelected: ', fsaIDSSelected)
                    // console.log('shapeCoordinates: ', shapeCoordinates)
                    // const polygon = window.L.polygon(shapeInvertedCoordinates, {
                    //   color: color,
                    //   weight: 1,
                    //   fill: true,
                    //   fillColor: color,
                    //   fillOpacity: 0.3
                    //   // pane: 'tilePane'
                    // }).addTo(this.map)
                    // polygon.bindTooltip(`<div> ${color} </div>`, {
                    //   permanent: true,
                    //   direction: 'center',
                    //   opacity: '1'
                    // })
                    const multi = shape.length > 1
                    insertZoneMiele(shapeCoordinates, zoneId, multi, fsaIDSSelected)
                  })
                }
                return Promise.resolve()
              })
              .then(async () => {
                await this.renderZonesToMap(false)
              })
          })
      })
      // })
    },
    /**
    * If the app is on edit mode. It reverts back to default mode
    */
    closeEditCurrentShapeMode () {
      this.editShapeSelectedMode = false
      this.selectedShape = null
      this.map.addControl(this.drawControl)
      this.map.closePopup()
    },
    /**
    * @params toAdd - checks if its adding or delete mode
    * Changes mode to Edit mode
    */
    editCurrentShapeModeOn (toAdd) {
      this.map.addControl(this.addDeletePartShapeModeDrawControl)
      this.map.removeControl(this.drawControl)
      this.map.closePopup()
      // new window.L.Draw.Rectangle(this.map).enable()
      if (toAdd) {
        this.addMoreShapeMode = true
      } else {
        this.deletePartShapeMode = true
      }
      // Make FSA layer button unclickable
      const fsaTargetElement = document.querySelector(
        '.leaflet-control-layers-overlays > :first-child'
      )
      fsaTargetElement.style.pointerEvents = 'none'
    },
    /**
    * Reverts to normal mode if the app is currently on edit mode
    */
    closeAddDeletePartShapeMode () {
      // new window.L.Draw.Rectangle(this.map).disable()
      this.deletePartShapeMode = false
      this.addMoreShapeMode = false
      this.selectedShape = null
      this.map.removeControl(this.addDeletePartShapeModeDrawControl)
      this.map.addControl(this.drawControl)
      const fsaTargetElement = document.querySelector(
        '.leaflet-control-layers-overlays > :first-child'
      )
      fsaTargetElement.style.pointerEvents = ''
    },
    /**
    * For DEVELOPMENT: Remove shapes that overlaps, usually the app won't allow that
    * Some zone that uploaded from Miele file overlaps so this function usually helps the zone remove overlaps
    */
    async removeOverlapZones () {
      // get current shape
      this.overlapLoading = true
      setTimeout(async () => {
        let existingCoods = []
        let existingShapes = []
        const selectedShape = this.selectedShape
        this.drawnZones
          .filter((d) => d.id !== selectedShape.id)
          .forEach((z) => {
            if (z.multi) {
              z.coordinates.forEach((zCood) => {
                existingCoods.push(zCood)
              })
              return
            }
            existingCoods.push(z.coordinates)
            existingShapes.push(z)
          })
        existingCoods = existingCoods.map((poly) =>
          poly.map((cood) => [cood[1], cood[0]])
        )
        // get all polygon that intersects in it
        existingCoods = existingCoods.filter((existCood) =>
          polygonIntersects(selectedShape.invertedCoordinates, existCood)
        )

        existingShapes = existingShapes.filter((existShp) =>
          polygonIntersects(
            selectedShape.invertedCoordinates,
            existShp.invertedCoordinates
          )
        )
        const overlappingPolygons = polygonTurf(existingCoods)
        console.log('selectedShape.invertedCoordinates:', selectedShape.invertedCoordinates)
        const selectedPolygon = polygonTurf([selectedShape.invertedCoordinates])
        const overlappingPolygonsCoordinates = overlappingPolygons.geometry.coordinates.map(
          (shape) => {
            return shape.map((c) => [c[0], c[1]])
          }
        )
        // make all overlapping polygons into one shape
        const toBeUnionedTurfPolygon = []
        overlappingPolygonsCoordinates.forEach((cood) => {
          const overlappingUnionPolygon = polygonTurf([cood])
          toBeUnionedTurfPolygon.push(overlappingUnionPolygon)
        })

        if (toBeUnionedTurfPolygon.length > 0) {
          const overlappingUnionPolygon = union(...toBeUnionedTurfPolygon)
          // get the remaining part of the selected shape
          const differenceShape = difference(
            selectedPolygon,
            overlappingUnionPolygon
          )
          if (differenceShape && differenceShape.geometry) {
            const differenceShapeCoordinates = differenceShape.geometry.coordinates.map(
              (shape) => {
                return shape.map((c) => [c[0], c[1]])
              }
            )
            // check if shape has holes
            const isMulti = differenceShapeCoordinates.length > 1
            const fsaIDSSelected = []
            this.drawnFSA.forEach(async (fsa) => {
              if (polygonIntersects(
                differenceShapeCoordinates,
                fsa.invertedCoordinates,
              )) {
                fsaIDSSelected.push(fsa.FSA_ID)
              }
            })
            // console.log('fsaIDSSelected: ', fsaIDSSelected)

            existingShapes.forEach(async (existShp) => {
              const currentExistingShapeNewFSA = []
              existShp.fsaIDs.forEach(async (fsaID) => {
                // fsaIDSSelected.includes(fsaID)
                if (!(fsaIDSSelected.includes(fsaID))) {
                  currentExistingShapeNewFSA.push(fsaID)
                }
              })
              await editZoneFSA({
                shapeId: existShp.id,
                fsa: currentExistingShapeNewFSA
              })
              // console.log('currentExistingShapeNewFSA: ', currentExistingShapeNewFSA)
            })

            if (differenceShapeCoordinates.length > 1) {
              // insert all coordintes including the hole coordinate
              await editZoneCoordinates({
                coordinates: differenceShapeCoordinates,
                shapeId: selectedShape.id,
                multi: isMulti,
              })
              await editZoneFSA({
                shapeId: selectedShape.id,
                fsa: fsaIDSSelected
              })
            } else {
              // if no holes just insert the original
              await editZoneCoordinates({
                coordinates: differenceShapeCoordinates[0],
                shapeId: selectedShape.id,
                multi: isMulti,
              })
              await editZoneFSA({
                shapeId: selectedShape.id,
                fsa: fsaIDSSelected
              })
            }
            // window.L.polygon(differenceShapeInvertedCoordinates, {
            //   color: 'black',
            //   weight: 2,
            //   fill: true,
            //   fillColor: 'black',
            //   fillOpacity: 0.5,
            //   // pane: 'tilePane',
            //   preferedCanvas: true
            // }).addTo(this.map)
          }
        }
        setTimeout(async () => {
          await this.renderZonesToMap(false)
        }, 1500)
        this.selectedShape = null
        this.overlapLoading = false
      }, 500)
    },
    /**
    * To render all of the zones to the map
    * Usually called after editing or writing on the Zone database
    */
    async renderZonesToMap (isGettingZone) {
      // this is to render all zones to the map
      const currentRegion = this.$store.state.region
      const cities = getProvinceCities(currentRegion)
      if (isGettingZone) {
        this.$notify({
          group: 'zones',
          title: 'Getting Zones',
          closeOnClick: false,
          duration: -1,
          text: `Retrieving zones for ${currentRegion}, please wait...`
        })
      } else {
        this.$notify({
          group: 'zones',
          title: 'Processing',
          closeOnClick: false,
          duration: -1,
          text: 'Data processing, please wait...'
        })
      }
      try {
        const res = await getZones(cities)
        const drawnShapes = res
          .map((doc) => ({ id: doc._id, ...doc }))
          .filter(res => getProvinceCities(this.$store.state.region.trim()).includes(res.region))
          // .filter(res => res.region === this.$store.state.region.trim())
        this.$store.dispatch('setRegionZones', drawnShapes)
        this.zones = drawnShapes.filter(
          (shape) => shape.region === this.$store.state.region
        )
        this.drawnZones
          .map((d) => d.leafletId)
          .forEach((id) => {
            this.map.removeLayer(this.map._layers[id])
          })
        this.markerGroup.clearLayers()
        this.drawnZones = []
        for (const x in this.drawnItems._layers) {
          this.drawnItems.removeLayer(this.drawnItems._layers[x])
        }
        // console.log('drawnShapes: ', drawnShapes)
        drawnShapes.map((shape) => {
          const coordinates = shape.multi
            ? JSON.parse(shape.coordinates).map((shape) => {
              return shape.map((c) => [c[1], c[0]])
            })
            : JSON.parse(shape.coordinates).map((c) => [c[1], c[0]])
          const invertedOrderCoordinates = shape.multi
            ? JSON.parse(shape.coordinates).map((shape) => {
              return shape.map((c) => [c[0], c[1]])
            })
            : JSON.parse(shape.coordinates).map((c) => [c[0], c[1]])
          const color = shape.color
          const zone = shape.zone
          const polygon = window.L.polygon(coordinates, {
            color: color,
            fillOpacity: 0.6,
            weight: 0.5
          }).addTo(this.map)
          const tooltip = window.L.tooltip({
            permanent: true,
            direction: 'center',
            opacity: '1',
            className: 'zone-tooltip'
          })
          tooltip.setContent(`<div style='background-color: transparent'> ${zone} </div>`)
          const { lat, lng } = polygon.getCenter()
          tooltip.setLatLng(new window.L.LatLng(lat, lng))
          this.markerGroup.addLayer(tooltip)
          // polygon.bindTooltip(tooltip)
          // polygon.bindTooltip(`<div style='background-color: transparent'> ${zone} </div>`, {
          //   permanent: true,
          //   direction: 'center',
          //   opacity: '1',
          //   className: 'zone-tooltip'
          // })
          // polygon.openTooltip(polygon.getCenter())
          // console.log('polygon.getCenter():', polygon.getCenter())
          const instance = EditShape(
            this.$store,
            invertedOrderCoordinates,
            shape,
            this.$store.state.selectedVehicles,
            this.editZoneDrawnzone,
            polygon._leaflet_id,
            this.map,
            this.editCurrentShapeModeOn,
            this.renderZonesToMap,
            this.activateEditSelectableFSAMode,
            this.closeEditCurrentShapeMode
          )
          instance.$mount()

          const contentPopup = instance.$el
          const zoneProperties = {
            zone,
            leafletId: polygon._leaflet_id,
            coordinates,
            id: shape.id,
            shapeId: shape.id,
            zoneId: shape.zoneId,
            region: shape.region,
            multi: shape.multi,
            invertedCoordinates: invertedOrderCoordinates,
            daysAssigned: shape.daysAssigned,
            fsaIDs: shape.fsa
          }
          this.drawnZones.push(zoneProperties)
          const popupContent = window.L.popup({
            closeButton: false,
            autoClose: false
          }).setContent(contentPopup)
          polygon.bindPopup(popupContent)
          this.drawnItems.addLayer(polygon)
        })
        // console.log('this.drawnZones', this.drawnZones.filter(x => x.multi).map(x => x.invertedCoordinates))
        this.$notify({
          group: 'zones',
          clean: true
        })
        if (isGettingZone) {
          this.$notify({
            type: 'success',
            group: 'zones',
            title: 'Zones retrieved',
            text: `Get all zones from ${currentRegion}`
          })
        } else {
          this.$notify({
            type: 'success',
            group: 'zones',
            title: 'Processed',
            text: 'Data successfully processed'
          })
        }
      } catch (err) {
        this.$notify({
          group: 'zones',
          clean: true
        })
        this.$notify({
          type: 'error',
          group: 'zones',
          title: 'There was an error, please refresh the page again'
        })
      }
    },
    /**
    * To render all of the FSA to the map
    * Automatically the app will be on add FSA mode
    */
    async renderFSAToMap (province) {
      this.drawnFSA = []
      const FSALayerGroup = window.L.featureGroup()
      const FSANameLayerGroup = window.L.featureGroup()
      this.FSALayerGroup = FSALayerGroup
      this.FSANameLayerGroup = FSANameLayerGroup
      this.retrievingFSA = true
      this.$notify({
        group: 'fsa',
        title: 'Getting FSA',
        closeOnClick: false,
        duration: -1,
        text: 'Retrieving FSA, please wait...'
      })
      // await getFSA(province)
      // Promise.all(require(`../../FSA/${province}`))
      const res = await getFSA(province)
      try {
        const fsaLocations = res
        // console.log('fsaLocations: ', fsaLocations)
        fsaLocations.map((shape) => {
          const coordinates = JSON.parse(shape.coordinates).map((c) => [
            c[1],
            c[0]
          ])
          const invertedCoordinates = JSON.parse(
            shape.coordinates
          ).map((c) => [c[0], c[1]])
          const FSA = shape.FSA
          const FSA_ID = shape.FSA_ID
          const polygon = window.L.polygon(coordinates, {
            color: 'black',
            weight: 1,
            fill: true,
            fillColor: '#A2D26C',
            fillOpacity: 0.4
            // pane: 'tilePane'
          }).addTo(FSALayerGroup)
          const FSAProperties = {
            FSA,
            FSA_ID,
            coordinates,
            invertedCoordinates,
            id: polygon._leaflet_id
          }
          this.drawnFSA.push(FSAProperties)
          // make fsa clickbale
          polygon.on('click', async (e) => {
            const selectedFSAId = e.sourceTarget._leaflet_id
            const selectedFSAShape = this.drawnFSA.find(
              (d) => d.id === selectedFSAId
            )
            console.log('selectedFSAShape.FSA_ID: ', selectedFSAShape.FSA_ID)
            console.log('selectedFSAShape: ', selectedFSAShape)
            if (this.selectableFSAMode) {
              const selectedFSAShapeToAdd = this.selectedFSAToAdd.find(
                (d) => d.id === selectedFSAId
              )
              // console.log('selectedFSAShapeToAdd:', selectedFSAShapeToAdd)
              if (!selectedFSAShapeToAdd) {
                let existingCoods = []
                const existingShapes = []
                if (this.editSelectableFSAMode && this.selectedShape) {
                  this.drawnZones
                    .filter((d) => d.id !== this.selectedShape.id)
                    .forEach((z) => {
                      if (z.multi) {
                        z.coordinates.forEach((zCood) => {
                          existingCoods.push(zCood)
                        })
                        existingShapes.push(z)
                        return
                      }
                      existingCoods.push(z.coordinates)
                      existingShapes.push(z)
                    })
                } else {
                  this.drawnZones.forEach((z) => {
                    if (z.multi) {
                      z.coordinates.forEach((zCood) => {
                        existingCoods.push(zCood)
                      })
                      existingShapes.push(z)
                      return
                    }
                    existingCoods.push(z.coordinates)
                    existingShapes.push(z)
                  })
                }
                // editSelectableFSAMode
                existingCoods = existingCoods.map((poly) =>
                  poly.map((cood) => [cood[1], cood[0]])
                )
                // console.log('selectedFSAShape.invertedCoordinates: ', selectedFSAShape.invertedCoordinates)
                existingCoods = existingCoods.filter((existCood) =>
                  polygonIntersects(
                    selectedFSAShape.invertedCoordinates,
                    existCood
                  )
                )

                let existingSelectedFSA = existingShapes
                  .reduce((acc, currentValue) => {
                    currentValue.fsaIDs.forEach((fsaID) => {
                      acc.push(fsaID)
                    })
                    return acc
                  }, [])
                existingSelectedFSA = [...new Set(existingSelectedFSA)]
                if (existingSelectedFSA.includes(selectedFSAShape.FSA_ID)) {
                // if (existingCoods.length > 0) {
                  this.$notify({
                    type: 'error',
                    group: 'zones',
                    title: 'There is an overlap',
                    text: 'The FSA you selected overlaps a existing zone'
                  })
                } else {
                  this.selectedFSAToAdd.push(selectedFSAShape)
                  await this.renderSelectedFSAShape()
                }
                // console.log('e', e)
                // console.log('selectedFSAId: ', selectedFSAId)
                // console.log('selectedFSAShape: ', selectedFSAShape)
              } else {
                console.log('already added')
                this.selectedFSAToAdd = this.selectedFSAToAdd.filter(
                  (d) => d.id !== selectedFSAId
                )
                await this.renderSelectedFSAShape()
              }
            }
          })
          const polygonFSA = polygonTurf([coordinates])
          const {
            geometry: { coordinates: centerPolygon }
          } = centerMass(polygonFSA)
          // const FSATooltipName =
          window.L.tooltip({
            permanent: true,
            sticky: true,
            direction: 'center',
            opacity: '1',
            className: 'FSA_tooltip',
            pane: 'tooltipPane'
          })
            .setContent(`${FSA}`)
            .setLatLng(centerPolygon)
            .addTo(FSANameLayerGroup)
        })
        const overLayerMaps = {
          FSA: FSALayerGroup,
          'FSA Name': FSANameLayerGroup
        }
        const layerControl = window.L.control.layers(null, overLayerMaps, {
          position: 'bottomleft'
        })
        this.FSALayerControl = layerControl
        window.FSALayerControl = layerControl
        layerControl.addTo(this.map)
        const fsaTargetElement = document.querySelector(
          '.leaflet-control-layers-overlays > :first-child'
        )
        const checkboxElement = fsaTargetElement.querySelector(
          '.leaflet-control-layers-selector'
        )
        const appThis = this
        checkboxElement.addEventListener('change', async function () {
          if (this.checked) {
            await appThis.selectableFSAModeOn(true)
          } else {
            await appThis.closeSelectableFSAMode(false, true)
          }
        })
        this.$notify({
          group: 'fsa',
          clean: true
        })
        this.$notify({
          type: 'success',
          group: 'fsa',
          title: 'FSA retrieved',
          text: `Get all FSA from ${province}`
        })
        this.retrievingFSA = false
        this.showChangingRegionModal = false
        window.FSALayerGroup = this.FSALayerGroup
      } catch (err) {
        this.$notify({
          group: 'fsa',
          clean: true
        })
        this.$notify({
          type: 'error',
          group: 'fsa',
          title: 'FSA not retrieved',
          text: `${err}`
        })
        this.retrievingFSA = false
        this.showChangingRegionModal = false
      }
    },
    /**
    * Open Modal on FSA mode when adding new shape
    */
    openAddFSAShapeZoneModal () {
      // after selecting some fsa
      // this will checker of the formats of the shape before
      // show the modal to comfirm it
      // const wholeSelectedCoordinates = this.selectedFSAToAdd.map(
      //   (fsa) => fsa.coordinates
      // )
      const wholeSelectedInvertedCoordinates = this.selectedFSAToAdd.map(
        (fsa) => fsa.invertedCoordinates
      )
      if (this.selectedFSAToAdd.length > 0) {
        const toBeUnionedTurfPolygon = []
        wholeSelectedInvertedCoordinates.map((cood) => {
          const currentSelected = polygonTurf([cood])
          toBeUnionedTurfPolygon.push(currentSelected)
        })
        const selectedUnionPolygon = union(...toBeUnionedTurfPolygon)
        const unionShapeCoordinates = selectedUnionPolygon.geometry.coordinates

        // check format if
        // single shape
        // single shape with hole
        // multiple single shape only
        // multiple single shape with wholes or not
        if (
          unionShapeCoordinates.length === 1 &&
          unionShapeCoordinates[0].every((cood) => cood.length === 2)
        ) {
          // single shape
          const invertedUnionShapeCoordinates = unionShapeCoordinates[0].map(
            (c) => [c[1], c[0]]
          )
          const fsaOfSingleShape = this.selectedFSAToAdd.map((fsa) => fsa.FSA_ID)
          // console.log('invertedUnionShapeCoordinates: ', invertedUnionShapeCoordinates)
          const shapeToAdd = {
            coordinates: unionShapeCoordinates,
            fsa: fsaOfSingleShape
          }
          this.renderFSAZoneToAddModal(
            invertedUnionShapeCoordinates,
            shapeToAdd,
            'SINGLE_SHAPE',
          )
        } else if (
          unionShapeCoordinates.length > 1 &&
          unionShapeCoordinates.some((shp) => shp.length > 4) &&
          unionShapeCoordinates
            .map((shp) => shp.every((cood) => cood.length === 2))
            .every((isShape) => isShape === true)
        ) {
          // single shape with hole
          const invertedUnionShapeCoordinates = unionShapeCoordinates.map(
            (shp) => {
              return shp.map((c) => [c[1], c[0]])
            }
          )
          const fsaOfSingleShapeWithHole = this.selectedFSAToAdd.map((fsa) => fsa.FSA_ID)
          const shapeToAdd = {
            coordinates: unionShapeCoordinates,
            fsa: fsaOfSingleShapeWithHole
          }
          this.renderFSAZoneToAddModal(
            invertedUnionShapeCoordinates,
            shapeToAdd,
            'SINGLE_SHAPE_WITH_HOLES'
          )
        } else if (unionShapeCoordinates.every((shp) => shp.length === 1)) {
          // multiple single shape
          const invertedUnionShapeCoordinates = unionShapeCoordinates.map(
            (shape) => shape[0].map((c) => [c[1], c[0]])
          )
          const unionShapeCoordinatesFlatten = unionShapeCoordinates.map(
            (shape) => shape[0].map((c) => [c[0], c[1]])
          )

          const shapesToAdd = []
          unionShapeCoordinatesFlatten.forEach((cood) => {
            const shapeFSA = []
            this.drawnFSA.forEach(async (fsa) => {
              if (polygonIntersects(
                cood,
                fsa.invertedCoordinates,
              )) {
                shapeFSA.push(fsa.FSA_ID)
              }
            })
            shapesToAdd.push({ coordinates: cood, fsa: shapeFSA })
          })
          this.renderFSAZoneToAddModal(
            invertedUnionShapeCoordinates,
            shapesToAdd,
            'MULTI_SINGLE_SHAPE'
          )
        } else {
          // multiple single shape with wholes
          const invertedUnionShapeCoordinates = unionShapeCoordinates.map(
            (shape) =>
              shape.map((shp) => {
                return shp.map((c) => [c[1], c[0]])
              })
          )
          const unionShapeCoordinatesFlatten = unionShapeCoordinates.map(
            (shape) =>
              shape.map((shp) => {
                return shp.map((c) => [c[0], c[1]])
              })
          )
          const shapesToAdd = []
          unionShapeCoordinatesFlatten.forEach((cood) => {
            const shapeFSA = []
            this.drawnFSA.forEach(async (fsa) => {
              if (polygonIntersects(
                cood,
                fsa.invertedCoordinates,
              )) {
                shapeFSA.push(fsa.FSA_ID)
              }
            })
            shapesToAdd.push({ coordinates: cood, fsa: shapeFSA })
          })
          this.renderFSAZoneToAddModal(
            invertedUnionShapeCoordinates,
            shapesToAdd,
            'MULTI_SINGLE_WITH_HOLES'
          )
        }
      }
    },
    /**
    * Open Modal on FSA mode when editing shape
    */
    openEditFSAShapeZoneModal () {
      // after selecting some fsa
      // this will checker of the formats of the shape before
      // show the modal to comfirm it
      // const wholeSelectedCoordinates = this.selectedFSAToAdd.map(
      //   (fsa) => fsa.coordinates
      // )
      const wholeSelectedInvertedCoordinates = this.selectedFSAToAdd.map(
        (fsa) => fsa.invertedCoordinates
      )
      if (this.selectedFSAToAdd.length > 0) {
        const toBeUnionedTurfPolygon = []
        wholeSelectedInvertedCoordinates.map((cood) => {
          const currentSelected = polygonTurf([cood])
          toBeUnionedTurfPolygon.push(currentSelected)
        })

        const selectedUnionPolygon = union(...toBeUnionedTurfPolygon)
        const unionShapeCoordinates = selectedUnionPolygon.geometry.coordinates

        if (
          unionShapeCoordinates.length === 1 &&
          unionShapeCoordinates[0].every((cood) => cood.length === 2)
        ) {
          // single shape
          this.selectableFSAMode = false
          this.currentSelectedFSAShape.forEach((fsaProperty) => {
            this.map.removeLayer(this.map._layers[fsaProperty.id])
          })
          const invertedUnionShapeCoordinates = unionShapeCoordinates[0].map(
            (c) => [c[1], c[0]]
          )
          const fsaOfSingleShape = this.selectedFSAToAdd.map((fsa) => fsa.FSA_ID)
          const shapeToAdd = {
            coordinates: unionShapeCoordinates,
            fsa: fsaOfSingleShape
          }
          this.renderFSAZoneToEditModal(
            invertedUnionShapeCoordinates,
            shapeToAdd,
            'SINGLE_SHAPE'
          )
        } else if (
          unionShapeCoordinates.length > 1 &&
          unionShapeCoordinates.some((shp) => shp.length > 4) &&
          unionShapeCoordinates
            .map((shp) => shp.every((cood) => cood.length === 2))
            .every((isShape) => isShape === true)
        ) {
          this.selectableFSAMode = false
          this.currentSelectedFSAShape.forEach((fsaProperty) => {
            this.map.removeLayer(this.map._layers[fsaProperty.id])
          })
          // single shape with hole
          const invertedUnionShapeCoordinates = unionShapeCoordinates.map(
            (shp) => {
              return shp.map((c) => [c[1], c[0]])
            }
          )
          const fsaOfSingleShapeWithHole = this.selectedFSAToAdd.map((fsa) => fsa.FSA_ID)
          const shapeToAdd = {
            coordinates: unionShapeCoordinates,
            fsa: fsaOfSingleShapeWithHole
          }
          this.renderFSAZoneToEditModal(
            invertedUnionShapeCoordinates,
            shapeToAdd,
            'SINGLE_SHAPE_WITH_HOLES'
          )
        } else if (unionShapeCoordinates.every((shp) => shp.length === 1)) {
          // multiple single shape
          const invertedUnionShapeCoordinates = unionShapeCoordinates.map(
            (shape) => shape[0].map((c) => [c[1], c[0]])
          )
          const unionShapeCoordinatesFlatten = unionShapeCoordinates.map(
            (shape) => shape[0].map((c) => [c[0], c[1]])
          )

          const shapesToAdd = []
          unionShapeCoordinatesFlatten.forEach((cood) => {
            const shapeFSA = []
            this.drawnFSA.forEach(async (fsa) => {
              if (polygonIntersects(
                cood,
                fsa.invertedCoordinates,
              )) {
                shapeFSA.push(fsa.FSA_ID)
              }
            })
            shapesToAdd.push({ coordinates: cood, fsa: shapeFSA })
          })
          this.renderFSAZoneToEditModal(
            invertedUnionShapeCoordinates,
            shapesToAdd,
            'MULTI_SINGLE_SHAPE'
          )
        } else {
          // multiple single shape with wholes
          const invertedUnionShapeCoordinates = unionShapeCoordinates.map(
            (shape) =>
              shape.map((shp) => {
                return shp.map((c) => [c[1], c[0]])
              })
          )
          const unionShapeCoordinatesFlatten = unionShapeCoordinates.map(
            (shape) =>
              shape.map((shp) => {
                return shp.map((c) => [c[0], c[1]])
              })
          )
          const shapesToAdd = []
          unionShapeCoordinatesFlatten.forEach((cood) => {
            const shapeFSA = []
            this.drawnFSA.forEach(async (fsa) => {
              if (polygonIntersects(
                cood,
                fsa.invertedCoordinates,
              )) {
                shapeFSA.push(fsa.FSA_ID)
              }
            })
            shapesToAdd.push({ coordinates: cood, fsa: shapeFSA })
          })
          this.renderFSAZoneToEditModal(
            invertedUnionShapeCoordinates,
            shapesToAdd,
            'MULTI_SINGLE_WITH_HOLES'
          )
          // this.$notify({
          //   type: 'error',
          //   group: 'zones',
          //   title: 'Shape cannot be combined',
          //   text: 'Shape selected cannot be combined to current one'
          // })
        }
      }
    },
    /**
    * Show FSA of the current region
    */
    selectableFSAModeOn (clickByCheckBox = false) {
      // this will activate selectable fsa mdoe and show fsa layer
      const fsaTargetElement = document.querySelector(
        '.leaflet-control-layers-overlays > :first-child'
      )
      const checkboxElement = fsaTargetElement.querySelector(
        '.leaflet-control-layers-selector'
      )
      this.map.removeControl(this.drawControl)
      this.map.closePopup()
      this.selectableFSAMode = true
      // this is for activating fsa layer manually
      if (!clickByCheckBox) {
        checkboxElement.click()
        fsaTargetElement.style.pointerEvents = 'none'
      }
    },
    /**
    * Activate FSA by selecting a shape then render current FSA assign on the current selected shape
    */
    async activateEditSelectableFSAMode () {
      // this will trigger to show all fsa to be clickable
      // this also will show all the fsa that the current shape overlaps
      this.map.removeControl(this.drawControl)
      this.map.closePopup()
      if (!this.selectableFSAMode) {
        const fsaTargetElement = document.querySelector(
          '.leaflet-control-layers-overlays > :first-child'
        )
        const checkboxElement = fsaTargetElement.querySelector(
          '.leaflet-control-layers-selector'
        )
        checkboxElement.click()
        fsaTargetElement.style.pointerEvents = 'none'
      }
      this.selectableFSAMode = true
      this.editSelectableFSAMode = true
      const selectedShape = this.selectedShape
      await this.drawnFSA.forEach(async (fsa) => {
        // const fsaShapeTurf = polygonTurf([invertedCoordinates])
        try {
          // fsaIDs // FSA_ID
          if (
            selectedShape.fsaIDs.includes(fsa.FSA_ID)
          ) {
            this.selectedFSAToAdd.push(fsa)
            await this.renderSelectedFSAShape()
          }
        } catch (err) {
          console.error('err: ', err)
        }
      })
      console.log('this.selectedFSAToAdd: ', this.selectedFSAToAdd)
    },
    /**
    * Hide FSA layers
    */
    closeSelectableFSAMode (changeRegion = false, checkByCheckbox = false) {
      if (!changeRegion) {
        const fsaTargetElement = document.querySelector(
          '.leaflet-control-layers-overlays > :first-child'
        )
        const checkboxElement = fsaTargetElement.querySelector(
          '.leaflet-control-layers-selector'
        )
        fsaTargetElement.style.pointerEvents = ''
        if (!checkByCheckbox) {
          checkboxElement.click()
        }
      }
      if (
        this.toBeSaveShapeFSAID &&
        this.map._layers[this.toBeSaveShapeFSAID]
      ) {
        this.map.removeLayer(this.map._layers[this.toBeSaveShapeFSAID])
        this.toBeSaveShapeFSAID = null
      }

      if (this.currentSelectedFSAShape.length > 0) {
        this.currentSelectedFSAShape.forEach((fsa) => {
          if (this.map._layers[fsa.id]) {
            this.map.removeLayer(this.map._layers[fsa.id])
          }
        })
      }

      this.selectedShape = null
      this.selectedFSAToAdd = []
      this.currentSelectedFSAShape = []
      this.map.addControl(this.drawControl)
      this.selectableFSAMode = false
      this.editSelectableFSAMode = false
      this.FSAConfirmMode = false
    },
    /**
    * To render every FSA on click
    */
    renderSelectedFSAShape () {
      // console.log('RENDER THIS', this.selectedFSAToAdd)
      const wholeSelectedZone = this.selectedFSAToAdd.map((fsa) => ({
        coordinates: fsa.coordinates,
        fsaId: fsa.id
      }))
      // const wholeSelectedInvertedCoordinates = this.selectedFSAToAdd.map(
      //   (fsa) => fsa.invertedCoordinates
      // )
      if (wholeSelectedZone.length > 0) {
        this.currentSelectedFSAShape.forEach((fsaProperty) => {
          this.map.removeLayer(this.map._layers[fsaProperty.id])
        })
        this.currentSelectedFSAShape = []
        wholeSelectedZone.map((fsa) => {
          const polygon = window.L.polygon([fsa.coordinates], {
            color: 'black',
            weight: 1,
            fill: true,
            fillColor: 'black',
            fillOpacity: 0.8,
            pane: 'shadowPane'
          }).addTo(this.map)
          const selectedShapeProperty = {
            id: polygon._leaflet_id
          }
          this.currentSelectedFSAShape.push(selectedShapeProperty)
          polygon.on('click', async (e) => {
            const selectedCurrentFSAId = e.sourceTarget._leaflet_id
            this.map.removeLayer(this.map._layers[selectedCurrentFSAId])
            this.currentSelectedFSAShape = this.currentSelectedFSAShape.filter(
              (d) => d.id !== selectedCurrentFSAId
            )
            this.selectedFSAToAdd = this.selectedFSAToAdd.filter(
              (d) => d.id !== fsa.fsaId
            )
          })
        })
      }
    },
    /**
    * Confirming shape after you the select it and show modal to choose what zone to assign
    */
    async renderFSAZoneToAddModal (
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType,
    ) {
      // this is the confirmation after adding some selected fsa and
      // display the modal
      const fsaTargetElement = document.querySelector(
        '.leaflet-control-layers-overlays > :first-child'
      )
      // const checkboxElement = fsaTargetElement.querySelector(
      //   '.leaflet-control-layers-selector'
      // )
      fsaTargetElement.style.pointerEvents = 'none'
      this.FSAConfirmMode = true
      this.selectableFSAMode = false
      this.currentSelectedFSAShape.forEach((fsaProperty) => {
        this.map.removeLayer(this.map._layers[fsaProperty.id])
      })
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: 'black',
        weight: 1,
        fill: true,
        fillColor: 'black',
        fillOpacity: 0.8
        // pane: 'tilePane'
      })
      // AddFSAZoneModal
      // .addTo(this.map)
      // console.log('invertedCenterCoordinate: ', invertedCenterCoordinate)
      this.drawnItems.addLayer(polygon)
      const ComponentClass = Vue.extend(AddFSAZoneModal)
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          region: this.$store.state.region,
          shapeType
        },
        methods: {
          close: () => {
            this.map.closePopup()
          },
          updateMap: async () => {
            setTimeout(async () => {
              await this.renderZonesToMap(false)
              // this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }, 1500)
          },
          removeShape: async () => {
            this.toBeSaveShapeFSAID = null
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }
            await this.closeSelectableFSAMode()
          },
          cancelEdit: async () => {
            await this.closeSelectableFSAMode()
          }
        }
      })
      instance.$mount()
      const contentPopup = instance.$el
      const popupContent = window.L.popup({
        closeButton: false,
        autoClose: false,
        closeOnClick: false
      }).setContent(contentPopup)
      polygon.bindPopup(popupContent).openPopup()
      this.toBeSaveShapeFSAID = polygon._leaflet_id
    },
    /**
     * Confirming shape after adding to show modal what zone to assign
     */
    async renderNewZoneToAddModal (
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: 'black',
        weight: 1,
        fill: true,
        fillColor: 'black',
        fillOpacity: 0.8
        // pane: 'tilePane'
      })
      // AddFSAZoneModal
      // .addTo(this.map)
      // console.log('invertedCenterCoordinate: ', invertedCenterCoordinate)
      this.drawnItems.addLayer(polygon)
      const ComponentClass = Vue.extend(AddFSAZoneModal)
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          region: this.$store.state.region,
          shapeType
        },
        methods: {
          close: () => {
            this.map.closePopup()
          },
          updateMap: async () => {
            setTimeout(async () => {
              await this.renderZonesToMap(false)
              // this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }, 1500)
          },
          removeShape: async () => {
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }
          },
          cancelEdit: async () => {
            this.map.closePopup()
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }
          }
        }
      })
      instance.$mount()
      const contentPopup = instance.$el
      const popupContent = window.L.popup({
        closeButton: false,
        autoClose: false,
        closeOnClick: false
      }).setContent(contentPopup)
      polygon.bindPopup(popupContent).openPopup()
    },
    /**
    * Confirming shape after you the select it and show modal to choose what zone to assign
    */
    async renderFSAZoneToEditModal (
      invertedUnionShapeCoordinates,
      shapesToAdd,
      shapeType
    ) {
      // this is the confirmation after adding some selected fsa and
      // display the modal
      const fsaTargetElement = document.querySelector(
        '.leaflet-control-layers-overlays > :first-child'
      )
      // const checkboxElement = fsaTargetElement.querySelector(
      //   '.leaflet-control-layers-selector'
      // )
      fsaTargetElement.style.pointerEvents = 'none'
      this.FSAConfirmMode = true
      this.selectableFSAMode = false
      const selectedShape = this.selectedShape
      const polygon = window.L.polygon(invertedUnionShapeCoordinates, {
        color: 'black',
        weight: 1,
        fill: true,
        fillColor: 'black',
        fillOpacity: 0.8
        // pane: 'tilePane'
      })
      this.drawnItems.addLayer(polygon)
      const ComponentClass = Vue.extend(EditFSAZoneModal)
      const instance = new ComponentClass({
        propsData: {
          shapesToAdd,
          shapeType,
          shape: selectedShape
        },
        methods: {
          close: () => {
            this.map.closePopup()
          },
          updateMap: async () => {
            setTimeout(async () => {
              await this.renderZonesToMap(false)
              // this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }, 1500)
          },
          removeShape: async () => {
            this.toBeSaveShapeFSAID = null
            if (this.map._layers[polygon._leaflet_id]) {
              this.map.removeLayer(this.map._layers[polygon._leaflet_id])
            }
            await this.closeSelectableFSAMode()
          },
          cancelEdit: async () => {
            await this.closeSelectableFSAMode()
          }
        }
      })
      // .addTo(this.map)
      instance.$mount()
      const contentPopup = instance.$el
      const popupContent = window.L.popup({
        closeButton: false,
        autoClose: false,
        closeOnClick: false
      }).setContent(contentPopup)
      polygon.bindPopup(popupContent).openPopup()
      this.toBeSaveShapeFSAID = polygon._leaflet_id
    },
    /**
    * To call database to edit current selected shape
    * @params coordinates - new coordinates for the current shape
    * @params shapeID - mongodb ID of the selected shape
    * @params multi - if shape has holes or none
    */
    async callEditZoneCoordinates (coordinates, shapeID, multi) {
      setTimeout(async () => {
        await editZoneCoordinates({
          coordinates,
          shapeId: shapeID,
          multi,
        })
        const fsa = await this.getShapeFSASelected(coordinates)
        await editZoneFSA({ shapeId: shapeID, fsa })
        this.$notify({
          group: 'zones',
          clean: true
        })
        this.$notify({
          type: 'success',
          group: 'zones',
          title: 'Shape saved',
          text: 'Shape is now updated'
        })
        setTimeout(async () => {
          await this.renderZonesToMap(false)
        }, 1000)
      }, 500)
    },
    /**
    * To call if selected shape is overlapping a zone
    * @params overlapCoordinates - new coordinates for the current shape
    */
    async addMoreShapeOverlapping (overlapCoordinates) {
      const selectedShape = this.selectedShape
      if (
        overlapCoordinates.length === 1 &&
        overlapCoordinates[0].length === 1 &&
        overlapCoordinates[0][0].every((cood) => cood.length === 2)
      ) {
        console.log('single shape')
        await this.callEditZoneCoordinates(
          overlapCoordinates[0][0],
          selectedShape.shapeId,
          false
        )
      } else if (
        overlapCoordinates.length === 1 &&
        overlapCoordinates[0][0].length > 1 &&
        overlapCoordinates[0].some((shp) => shp.length > 4) &&
        overlapCoordinates[0]
          .map((shp) => shp.every((cood) => cood.length === 2))
          .every((isShape) => isShape === true)
      ) {
        console.log('single shape w/ hole')
        await this.callEditZoneCoordinates(
          overlapCoordinates[0],
          selectedShape.shapeId,
          true
        )
      } else if (overlapCoordinates.every((shp) => shp.length === 1)) {
        Promise.all(overlapCoordinates.map(async (cood) => {
          // console.log('cood: ', cood)
          // console.log('getShapeFSASelected: ', await this.getShapeFSASelected(cood))
          const fsa = await this.getShapeFSASelected(cood)
          await insertZone(
            cood[0],
            selectedShape.zoneId,
            false,
            fsa
          )
        }))
        await deleteZone(selectedShape.id)
        this.$notify({
          group: 'zones',
          clean: true
        })
        this.$notify({
          type: 'success',
          group: 'zones',
          title: 'Shape saved',
          text: 'Shape is now updated'
        })
        setTimeout(async () => {
          await this.renderZonesToMap(false)
        }, 1000)
      } else {
        // multiple shapes with wholes or not
        console.log('multi shape single w/ whole')
        Promise.all(overlapCoordinates.map(async (cood) => {
          const fsa = await this.getShapeFSASelected(cood)
          if (cood.length > 1) {
            await insertZone(
              cood,
              selectedShape.zoneId,
              true,
              fsa
            )
          } else {
            await insertZone(
              cood[0],
              selectedShape.zoneId,
              false,
              fsa
            )
          }
        }))
        await deleteZone(selectedShape.id)
        this.$notify({
          group: 'zones',
          clean: true
        })
        this.$notify({
          type: 'success',
          group: 'zones',
          title: 'Shape saved',
          text: 'Shape is now updated'
        })
        setTimeout(async () => {
          await this.renderZonesToMap(false)
        }, 1000)
      }
    },
    /**
    * Get FSA using coordinates
    * @params coordinates - coordinates to get FSA
    */
    async getShapeFSASelected (coordinates) {
      const fsaIDSSelected = []
      this.drawnFSA.forEach(async (fsa) => {
        if (polygonIntersects(
          coordinates,
          fsa.invertedCoordinates,
        )) {
          fsaIDSSelected.push(fsa.FSA_ID)
        }
      })
      return fsaIDSSelected
    },
    async refresh () {
      this.refreshLoading = true
      await this.renderZonesToMap()
      this.refreshLoading = false
    }
  },
  computed: {
    ...mapState(['selectedVehicles', 'region'])
  },
  mounted () {
    const currentEnvironment = this.$router.currentRoute.name
    this.$store.dispatch('setEnvironment', currentEnvironment)
    // console.log('currentEnvironment', currentEnvironment)
    this.showChangingRegionModal = true
    this.mappingLoaded = true
    this.map = this.$refs.map.mapObject
    window.map = this.map
    const drawnItems = new window.L.FeatureGroup()
    this.drawnItems = drawnItems
    const FSAItems = new window.L.FeatureGroup()
    this.FSAItems = FSAItems
    const drawnMarkers = new window.L.FeatureGroup()
    const markerGroup = window.L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      chunkedLoading: true,
      animate: false,
      iconCreateFunction: (cluster) => {
        return window.L.divIcon({ html: '', className: 'marker-cluster-icon' })
      }
    })
    this.markerGroup = markerGroup
    // Define you draw handler somewhere where you click handler can access it. N.B. pass any draw options into the handler
    this.map.addLayer(this.drawnItems)
    this.map.addLayer(drawnMarkers)
    this.map.addLayer(markerGroup)
    // toolbar for polygon
    window.L.EditToolbar.Delete.include({
      removeAllLayers: false
    })

    const drawControl = new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        edit: false
      },
      draw: {
        polyline: false,
        polygon: {
          shapeOptions: {
            clickable: true,
            color: '#00D1B2'
          },
          allowIntersection: false
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            color: '#00D1B2'
          }
        },
        circle: false,
        marker: {
          shapeOptions: {
            clickable: true,
            color: '#6169e0'
          }
        },
        circlemarker: false
      }
    })
    // this control is for add or deleting shapes in a zone
    const addDeletePartShapeModeDrawControl = new window.L.Control.Draw({
      edit: {
        featureGroup: this.drawnItems,
        edit: false,
        remove: false
      },
      draw: {
        polyline: false,
        polygon: {
          shapeOptions: {
            clickable: true,
            color: '#00D1B2'
          },
          allowIntersection: false
        },
        rectangle: {
          shapeOptions: {
            clickable: true,
            color: '#d1001f'
          }
        },
        circle: false,
        marker: false,
        circlemarker: false
      }
    })
    // This section is to test if the client calls the server works in the mongodb
    // fetch('http://localhost:5001/geospatial-41457/us-central1/MAPPING-getPostalCode').then(async (res) => {
    //   const locations = await res.json()
    //   locations.result.map(loc => {
    //     if (loc.geometry.coordinates[1] && loc.geometry.coordinates[0] && loc.properties.Postcode) {
    //       const marker = new window
    //         .L.Marker([loc.geometry.coordinates[1], loc.geometry.coordinates[0]])
    //         .addTo(this.map)
    //       marker.bindPopup(`<b>${loc.properties.Postcode}</b>`)
    //       // .openPopup()
    //     }
    //   })
    //   // this.locations = locations.map(l => l.postal_code)
    //   // console.log('coordinatesPushes', coordinatesPushes)
    //   // console.log('PostcodePushes', PostcodePushes)
    //   //
    // })
    this.drawControl = drawControl
    this.addDeletePartShapeModeDrawControl = addDeletePartShapeModeDrawControl
    this.map.addControl(this.drawControl)

    // ADD VEHICLES
    fleetSettings
      .get()
      .then(async (snapshot) => {
        snapshot.docs.map((doc) => {
          this.regions.push(doc.id)
          if (doc.data().fleet) {
            this.vehicleObj[doc.id] = doc
              .data()
              .fleet.map((t, index) => ({ ...t, truckIndex: index }))
          } else {
            this.vehicleObj[doc.id] = []
          }
        })
        this.vehicles = this.vehicleObj[this.regions[this.selectedRegionIndex]]
        // SET to First
        // this.$store.dispatch(
        //   'setRegion',
        //   this.regions[this.selectedRegionIndex]
        // )
        // set to calgary
        this.$store.dispatch(
          'setRegion',
          'calgary'
        )
        this.center = regionCenter('calgary')
        this.$store.dispatch(
          'setSelectedVehicles',
          this.vehicleObj.calgary
        )
        this.$store.dispatch('setInitialFleetSettings', this.vehicleObj)
        // need to resolve to proceed to get the specific FSA locations
        await this.renderFSAToMap(
          getProvince('calgary')
        )
      })

    // on select shape
    this.drawnItems.on('click', (e) => {
      const selectedShapeId = e.sourceTarget._leaflet_id
      const selectedShape = this.drawnZones.find(
        (d) => d.leafletId === selectedShapeId
      )
      if (
        selectedShape &&
        !this.editShapeSelectedMode &&
        !this.addMoreShapeMode &&
        !this.deletePartShapeMode &&
        !this.selectableFSAMode &&
        !this.editSelectableFSAMode
      ) {
        this.selectedShape = selectedShape
      }
    })

    this.map.on('draw:created', async (e) => {
      const layer = e.layer
      const color = randomColor()
      // check if the mode is in delete or adding more shapes to a zone or
      // adding parts zone
      // delete parts zone
      // add new zone or marker
      if (this.addMoreShapeMode) {
        this.$notify({
          group: 'zones',
          title: 'Processing...',
          text: 'Updating Zone, please wait'
        })
        try {
          setTimeout(async () => {
            const selectedShape = this.selectedShape
            const coordinates = layer.getLatLngs()
            const coods = coordinates[0].map((c) => [c.lat, c.lng])
            coods.push([coordinates[0][0].lat, coordinates[0][0].lng])
            const invertedCoods = coods.map((c) => [c[1], c[0]])
            const selectedShapeTurf = selectedShape.multi
              ? polygonTurf(selectedShape.coordinates)
              : polygonTurf([selectedShape.coordinates])
            const currentShapeToBeAdded = polygonTurf([coods])
            const combinedShape = [selectedShapeTurf, currentShapeToBeAdded]
            const combinedPolygon = union(...combinedShape)
            const combineCoordinates = combinedPolygon.geometry.coordinates.map(
              (shape) => {
                return shape.map((c) => [c[1], c[0]])
              }
            )

            let existingCoods = []
            this.drawnZones
              .filter((d) => d.id !== selectedShape.id)
              .forEach((z) => {
                existingCoods.push(z.coordinates)
              })
            // check cood if really single
            existingCoods = existingCoods.map((poly) => {
              if (poly &&
                poly.length > 0 &&
                poly.every((cood) => cood.length === 2)
              ) {
                return poly.map((cood) => [cood[1], cood[0]])
              } else {
                return poly.map(shape => {
                  return shape.map((cood) => [cood[1], cood[0]])
                })
              }
            })
            // if (polygonIntersects(fsa.invertedCoordinates, selectedShape.invertedCoordinates)) {
            existingCoods = existingCoods.filter((existCood) =>
              polygonIntersects(combineCoordinates, existCood)
            )
            // if shape is more than 1
            if (combinedPolygon.geometry.coordinates.length > 1) {
              // check if its just 1 shape with many wholes
              const validShape = combineCoordinates.some((shp) => shp.length > 4)
              if (validShape) {
                if (existingCoods.length > 0) {
                  const existingCoodsToPolygon = []
                  existingCoods.forEach(cood => {
                    if (cood && cood.every((cood) => cood.length === 2)) {
                      existingCoodsToPolygon.push([cood])
                    } else {
                      existingCoodsToPolygon.push(cood)
                    }
                  })
                  const removeOverlapCoordinates = differenceClipping(
                    combineCoordinates,
                    existingCoodsToPolygon
                  )
                  const removeOverlapSelectedCoordinates = differenceClipping(
                    [invertedCoods],
                    existingCoodsToPolygon
                  )
                  console.log('removeOverlapSelectedCoordinates:', removeOverlapSelectedCoordinates)
                  await this.addMoreShapeOverlapping(removeOverlapCoordinates)
                } else {
                  await this.callEditZoneCoordinates(
                    combineCoordinates,
                    selectedShape.shapeId,
                    true
                  )
                }
              } else {
                this.$notify({
                  group: 'zones',
                  clean: true
                })
                this.$notify({
                  type: 'error',
                  group: 'zones',
                  title: 'Shape not saved',
                  text: 'The shape you wanted is not a valid shape'
                })
              }
            } else {
              if (existingCoods.length > 0) {
                const existingCoodsToPolygon = []
                existingCoods.forEach(cood => {
                  if (cood && cood.every((cood) => cood.length === 2)) {
                    existingCoodsToPolygon.push([cood])
                  } else {
                    existingCoodsToPolygon.push(cood)
                  }
                })
                const removeOverlapCoordinates = differenceClipping(
                  combineCoordinates,
                  existingCoodsToPolygon
                )
                this.addMoreShapeOverlapping(removeOverlapCoordinates)
              } else {
                this.callEditZoneCoordinates(
                  combineCoordinates[0],
                  selectedShape.shapeId,
                  false
                )
              }
            }
            this.closeAddDeletePartShapeMode()
          }, 500)
        } catch (err) {
          this.$notify({
            type: 'error',
            group: 'zones',
            title: 'Error.',
            text: 'There is something wrong on updating zone, please try again.'
          })
        }
      } else if (this.deletePartShapeMode) {
        this.$notify({
          group: 'zones',
          title: 'Processing...',
          text: 'Updating Zone, please wait'
        })
        try {
          setTimeout(async () => {
            const coordinates = layer.getLatLngs()
            const selectedShape = this.selectedShape
            const coods = coordinates[0].map((c) => [c.lat, c.lng])
            coods.push([coordinates[0][0].lat, coordinates[0][0].lng])
            const selectedShapeTurf = selectedShape.multi
              ? polygonTurf(selectedShape.coordinates)
              : polygonTurf([selectedShape.coordinates])
            const currentShapeToBeDeleted = polygonTurf([coods])
            const differenceShape = difference(
              selectedShapeTurf,
              currentShapeToBeDeleted
            )
            if (differenceShape) {
              if (differenceShape.geometry.coordinates.length > 1) {
                const differenceShapeCoordinates = differenceShape.geometry.coordinates.map(
                  (shape) => {
                    return shape.map((c) => [c[0], c[1]])
                  }
                )
                const differenceShapeInvertedCoordinates = differenceShape.geometry.coordinates.map(
                  (shape) => {
                    return shape.map((c) => [c[1], c[0]])
                  }
                )
                const validShape = differenceShapeCoordinates.some(
                  (shp) => shp.length > 4
                )
                if (validShape) {
                  await this.callEditZoneCoordinates(
                    differenceShapeInvertedCoordinates,
                    selectedShape.shapeId,
                    true
                  )
                } else {
                  this.$notify({
                    group: 'zones',
                    clean: true
                  })
                  this.$notify({
                    type: 'error',
                    group: 'zones',
                    title: 'Shape not saved',
                    text: 'The shape you wanted is not a valid shape'
                  })
                }
              } else {
                const differenceShapeInvertedCoordinates = differenceShape.geometry.coordinates.map(
                  (shape) => {
                    return shape.map((c) => [c[1], c[0]])
                  }
                )
                await this.callEditZoneCoordinates(
                  differenceShapeInvertedCoordinates[0],
                  selectedShape.shapeId,
                  false
                )
              }
            } else {
              this.$notify({
                group: 'zones',
                clean: true
              })
              this.$notify({
                type: 'error',
                group: 'zones',
                title: 'Can\'t delete shape',
                text: 'You cannot delete the entire shape'
              })
            }
            this.closeAddDeletePartShapeMode()
          }, 500)
        } catch (err) {
          this.$notify({
            type: 'error',
            group: 'zones',
            title: 'Error.',
            text: 'There is something wrong on updating zone, please try again.'
          })
        }
      } else {
        if (e.layerType === 'marker') {
          const { lat, lng } = layer._latlng
          const marker = new window.L.Marker([lat, lng], {
            icon: this.getIcon(false, color, ''),
            riseOnHover: true
          })
          const ComponentClass = Vue.extend(AddMarkerZoneModal)
          const instance = new ComponentClass({
            propsData: {
              coordinates: [lat, lng],
              region: this.$store.state.region,
              color: color
            },
            methods: {
              close: (hubIndex) => {
                if (hubIndex) {
                  marker.setIcon(this.getIcon(false, color, hubIndex))
                }
                this.map.closePopup()
              }
            }
          })
          instance.$mount()
          const contentPopup = instance.$el

          // marker.bindPopup(instance.$el)
          // this.map.addLayer(marker)
          window.L.popup({
            keepInView: true,
            closeButton: true,
            autoClose: true,
            autoPan: true,
            closeOnClick: false
          })
            .setLatLng([lat, lng])
            .setContent(contentPopup)
            .openOn(this.map)
          marker.bindPopup(contentPopup)
          // layer.openPopup()
          drawnMarkers.addLayer(marker)
        } else {
          const coordinates = layer.getLatLngs()
          this.drawnItems.addLayer(layer)
          const coods = coordinates[0].map((c) => [c.lng, c.lat])
          coods.push([coordinates[0][0].lng, coordinates[0][0].lat])
          this.coordinatesStr = coods
          let existingCoods = []
          const existingZones = []
          this.drawnZones.forEach((z) => {
            existingCoods.push(z.coordinates)
            existingZones.push(z)
          })
          existingCoods = existingCoods.map((poly) => {
            // check if shape has wholes or not simple
            if (poly.every((cood) => cood.length === 2)) {
              return poly.map((cood) => [cood[1], cood[0]])
            } else {
              return poly.map((shape) =>
                shape.map((cood) => [cood[1], cood[0]])
              )
            }
          })
          console.log('existingZones:', existingZones)
          // const intersecting = existingCoods.some((existCoods) =>
          //   polygonIntersects(coods, existCoods)
          // )
          const intersecting = existingZones.some((zones) =>
            polygonIntersects(coods, zones.invertedCoordinates)
          )
          if (intersecting) {
            // const intersectingZones = []
            const intersectingZonesToPolygon = []
            this.map.removeLayer(this.map._layers[layer._leaflet_id])
            await existingZones.forEach((zone) => {
              if (polygonIntersects(coods, zone.invertedCoordinates)) {
                console.log('zone:', zone)
                const zoneCood = zone.invertedCoordinates.every((cood) => cood.length === 2)
                  ? [zone.invertedCoordinates]
                  : zone.invertedCoordinates
                const toPolygonZone = polygonTurf(zoneCood)
                intersectingZonesToPolygon.push(toPolygonZone)
              }
            })

            const combinedExistingPolygon = union(...intersectingZonesToPolygon)
            console.log('combinedExistingPolygon:', combinedExistingPolygon)
            const combineCoordinates = combinedExistingPolygon.geometry.coordinates.map(
              (shape) => {
                if (shape.every((cood) => cood.length === 2)) {
                  return shape.map((c) => [c[0], c[1]])
                } else {
                  console.log('wholes')
                  return shape.map((shp) => {
                    return shp.map((c) => [c[0], c[1]])
                  })
                }
              })
            // const invertCombineCoordinates = combinedExistingPolygon.geometry.coordinates.map(
            //   (shape) => {
            //     if (shape.every((cood) => cood.length === 2)) {
            //       return shape.map((c) => [c[1], c[0]])
            //     } else {
            //       console.log('wholes')
            //       return shape.map((shp) => {
            //         return shp.map((c) => [c[1], c[0]])
            //       })
            //     }
            //   })

            console.log('combineCoordinates:', combineCoordinates)
            console.log('coods:', coods)

            const removeOverlapCoordinates = differenceClipping(
              [coods],
              combineCoordinates
            )
            console.log('removeOverlapCoordinates: ', removeOverlapCoordinates)
            const removeInvertCombineCoordinates = removeOverlapCoordinates.map(
              (shape) => {
                if (shape.every((cood) => cood.length === 2)) {
                  return shape.map((c) => [c[1], c[0]])
                } else {
                  return shape.map((shp) => {
                    return shp.map((c) => [c[1], c[0]])
                  })
                }
              })
            // console.log('invertCombineCoordinates:', invertCombineCoordinates)
            // const color = 'black'
            // const polygon = window.L.polygon(removeInvertCombineCoordinates, {
            //   color: color,
            //   weight: 1,
            //   fill: true,
            //   fillColor: color,
            //   fillOpacity: 0.3
            //   // pane: 'tilePane'
            // }).addTo(this.map)
            // polygon.bindTooltip(`<div> ${color} </div>`, {
            //   permanent: true,
            //   direction: 'center',
            //   opacity: '1'
            // })
            const shapesToAdd = []
            removeOverlapCoordinates.forEach((cood) => {
              const shapeFSA = []
              this.drawnFSA.forEach(async (fsa) => {
                if (polygonIntersects(
                  cood,
                  fsa.invertedCoordinates,
                )) {
                  shapeFSA.push(fsa.FSA_ID)
                }
              })
              shapesToAdd.push({ coordinates: cood, fsa: shapeFSA })
            })
            console.log('shapesToAdd:', shapesToAdd)
            this.renderNewZoneToAddModal(
              removeInvertCombineCoordinates,
              shapesToAdd,
              'MULTI_SINGLE_WITH_HOLES'
            )
          } else {
            const ComponentClass = Vue.extend(AddShapeModal)
            const instance = new ComponentClass({
              propsData: {
                coordinates: coods,
                region: this.$store.state.region
              },
              methods: {
                close: () => {
                  this.map.closePopup()
                  if (this.map._layers[layer._leaflet_id]) {
                    this.map.removeLayer(this.map._layers[layer._leaflet_id])
                  }
                },
                updateMap: async () => {
                  setTimeout(async () => {
                    await this.renderZonesToMap(false)
                  }, 1000)
                }
              }
            })
            instance.$mount()
            const contentPopup = instance.$el

            const popupCoords = e.layer.getCenter()
            window.L.popup({
              keepInView: true,
              closeButton: true,
              autoClose: true,
              autoPan: true,
              closeOnClick: false
            })
              .setLatLng([popupCoords.lat, popupCoords.lng])
              .setContent(contentPopup)
              .openOn(this.map)
            layer.bindPopup(contentPopup)
            // layer.openPopup()
            this.drawnItems.addLayer(layer)
          }
        }
      }
    })

    // on edit save/stop
    this.map.on('draw:editstop', () => {
      // if the user save or cancel, this must be updated
      this.editedZones.map((zone) => {
        const drawnZone = this.drawnZones.find(
          (o) => o.leafletId === zone.leafletId
        )
        const coordinates = drawnZone.coordinates.map((c) => [c[1], c[0]])
        const instance = EditShape(
          this.$store,
          coordinates,
          zone,
          this.vehicles,
          this.editZoneDrawnzone,
          zone.leafletId,
          this.map,
          this.editCurrentShapeModeOn,
          this.renderZonesToMap,
          this.activateEditSelectableFSAMode,
          this.closeEditCurrentShapeMode
        )
        instance.$mount()
        const contentPopup = instance.$el
        this.map._layers[zone.leafletId].setPopupContent(contentPopup)
      })
    })

    // on edit move vertex
    this.map.on('draw:editvertex', (e) => {
      const poly = e.poly
      const latlngs = poly.getLatLngs()[0].map((c) => [c.lng, c.lat])
      latlngs.push([latlngs[0][0], latlngs[0][1]])
      const leafletId = poly._leaflet_id

      const drawnZone = this.drawnZones.find((o) => o.leafletId === leafletId)
      if (!this.editedZones.find((o) => o.leafletId === leafletId)) {
        this.editedZones.push({
          leafletId,
          zone: drawnZone.zone,
          coordinates: latlngs,
          id: drawnZone.id,
          multi: drawnZone.multi
        })
      } else {
        const editedDrawnZoneIndex = this.editedZones.findIndex(
          (o) => o.leafletId === leafletId
        )
        this.editedZones[editedDrawnZoneIndex].coordinates = latlngs
      }
      const instance = EditShape(
        this.$store,
        latlngs,
        drawnZone,
        this.vehicles,
        this.editZoneDrawnzone,
        leafletId,
        this.map,
        this.editCurrentShapeModeOn,
        this.renderZonesToMap,
        this.activateEditSelectableFSAMode,
        this.closeEditCurrentShapeMode
      )
      instance.$mount()
      const contentPopup = instance.$el
      poly.setPopupContent(contentPopup)
    })

    // on edit save.
    this.map.on('draw:edited', async () => {
      this.editedZones.map(async (zone) => {
        await editZoneCoordinates({
          coordinates: zone.coordinates,
          shapeId: zone.id,
          multi: zone.multi
        })
      })
      this.editedZones = []
      setTimeout(async () => {
        await this.renderZonesToMap(false)
      }, 1500)
    })

    // on delete
    this.map.on('draw:deleted', async (e) => {
      const deleteLayersId = Object.keys(e.layers._layers).map(Number)
      await deleteLayersId.map(async (id) => {
        const drawnZone = this.drawnZones.find((o) => o.leafletId === id)
        // remove draw zone from the leaflet
        this.drawnZones = this.drawnZones.filter((o) => o.leafletId !== id)
        const regions = this.regions
        // when removing zone, you must remove the assigned zone from the truck
        regions.map(async (reg, index) => {
          const selectedTruck = this.vehicleObj[this.regions[index]]
            .map((t, index) => ({ ...t, truckIndex: index }))
            .filter((truck) => truck.zone === drawnZone.zone)
          selectedTruck.map((truck) => {
            const payload = {
              value: '',
              key: 'zone',
              index: truck.truckIndex,
              region: reg
            }
            this.$store.dispatch('setTruckFleetSetting', payload)
          })
          await fleetSettings
            .doc(`${reg}`)
            .set({ fleet: this.$store.state.fleetSettings[reg] })
        })
        return deleteZone(drawnZone.shapeId)
      })
      setTimeout(async () => {
        await this.renderZonesToMap(false)
      }, 1500)
    })

    // initial get all zones
    this.renderZonesToMap(true)

    // listener
    hubsCollection.onSnapshot((snapshot) => {
      const drawnHubs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      this.$store.dispatch('setHubs', drawnHubs)
      this.markers = drawnHubs.filter(
        (shape) => shape.regionHub === this.$store.state.region
      )
      this.drawnMarkers
        .map((d) => d.leafletId)
        .forEach((id) => {
          this.map.removeLayer(this.map._layers[id])
        })
      this.drawnMarkers = []
      for (const x in drawnMarkers._layers) {
        drawnMarkers.removeLayer(drawnMarkers._layers[x])
      }
      drawnHubs.map((hub) => {
        const coordinates = JSON.parse(hub.coordinates)
        const color = hub.color
        const hubIndex = hub.hubIndex
        const facility = hub.facility
        const hubType = hub.hubType
        const nameHub = hub.nameHub
        const regionHub = hub.regionHub
        const size = hub.size
        const hubID = hub.id
        const marker = new window.L.Marker(coordinates, {
          icon: this.getIcon(false, color, hubIndex),
          riseOnHover: true
        }).addTo(this.map)
        //   const zone = shape.zone
        const instance = EditMarker(
          this.$store,
          marker._leaflet_id,
          () => {
            console.log('Edit')
          },
          this.map,
          coordinates,
          hubID,
          facility,
          hubIndex,
          hubType,
          nameHub,
          regionHub,
          size
        )
        instance.$mount()

        const contentPopup = instance.$el
        const markerProperties = {
          nameHub,
          leafletId: marker._leaflet_id,
          coordinates,
          id: hubID,
          region: hub.regionHub
        }
        this.drawnMarkers.push(markerProperties)
        marker.bindPopup(contentPopup)
        drawnMarkers.addLayer(marker)
      })
    })

    zonesInfoCollection.onSnapshot((snapshot) => {
      const zoneInformations1 = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))

      // console.log('zoneInformations1:', zoneInformations1)
      const zoneInformations = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter((doc) => doc.environment === this.$store.state.environment)
      this.$store.dispatch('setZoneInformations', zoneInformations)
    })
  }

}

// fix icon for marker
delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
</script>
<style>
.leaflet-popup-content-wrapper {
  width: 53vw;
}
.FSA-Content > .leaflet-popup-content-wrapper {
  width: 8vw;
}
.leaflet-popup-content {
  width: 90% !important;
}
.FSA_tooltip {
  /* background: transparent; */
  border: none;
  font-weight: 600;
  font-style: italic;
}
</style>
<style lang="scss" scoped>
@import '~leaflet/dist/leaflet.css';
.map-container {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.fit {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.leaflet-control-layers-overlays {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
